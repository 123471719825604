@use '@farmersdog/corgi-x/src/styles/constants-legacy.scss' as *;

.container {
  display: flex;
}

.xs {
  flex-direction: column-reverse;
  margin-top: $margin-xxl;
  margin-bottom: $margin-xxl;
}

.lg {
  flex-direction: row;
  margin-top: $margin-4xl;
  margin-bottom: $margin-4xl;
}

.image {
  width: 100%;
  display: block;
}

.content {
  margin: 0;
}

.xs .content {
  margin-top: $margin-xl;
}

.lg .content {
  margin-top: 0;
}

.cta {
  margin-top: $margin-lg;
  text-align: center;
}
