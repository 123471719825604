@use '@farmersdog/corgi-x/src/styles/constants-legacy.scss' as *;

.banner {
  min-height: var(--tfd-banner-min-height);
}

.header {
  position: sticky;
  top: 0;
  z-index: var(--tfd-appnav-z-index);
}

.vetLinkCtaCard {
  border-radius: 4px;
  background-color: var(--chickpea-0);
  margin-bottom: var(--margin-md);
  padding: var(--margin-md);
  display: flex;
  flex-direction: column;
  align-items: flex-start;
}

.vetLinkCtaCardButton {
  align-self: stretch;
  background-color: transparent !important;
}

.vetLinkPill {
  margin: var(--tfd-spacing-sm) var(--tfd-spacing-sm);

  &.hideOnMobile {
    @media (min-width: $sm) {
      display: none;
    }

    @media (min-width: $lg) {
      display: block;
    }
  }

  @media (min-width: $lg) {
    margin: 0 calc(var(--tfd-spacing-lg) / 2);
  }
}
