@use 'sass:color';

@use '@farmersdog/corgi-x/src/styles/constants-legacy.scss' as *;
@use 'constants/fonts.scss' as *;
@use 'styles/utils.scss' as *;

.Button {
  @include button-reset();
}

.Button.inline {
  vertical-align: text-bottom;
}

%button {
  display: inline-block;
  box-sizing: border-box;
  padding-right: 25px;
  padding-left: 25px;
  height: 44px;
  border-radius: 3px;
  vertical-align: middle;
  text-align: center;
  text-transform: uppercase;
  white-space: nowrap;
  letter-spacing: 1.5px;
  font-weight: 900;
  font-size: 13px;
  font-family: $legacy-sans-serif;
  line-height: 44px;
  cursor: pointer;
  &[disabled] {
    cursor: default;
  }
  &.inline {
    padding-right: 0;
    padding-left: 0;
    height: auto;
    line-height: inherit;
    > .Button-label {
      top: 0;
    }
  }
  &.block {
    display: block;
    width: 100%;
  }
  &.loading {
    position: relative;

    border-color: transparent !important;
    background-color: transparent !important;
    color: transparent !important;
    .ActivityIndicator {
      position: absolute;
      top: 7px;
      left: 50%;

      transform: translateX(-50%);
    }
  }
}

// Fixes wrong line height with our font
.Button:not(.link):not(.bordered):not(.bordered-alternate) > .Button-label {
  position: relative;
  top: 2px;
}

.Button-label > svg {
  position: relative;
  top: -2px; // Fixes wrong line height with our font

  display: inline-block;
  margin-right: 4px;
  width: 22px;
  height: 22px;
  vertical-align: middle;
}

@mixin fill-button($color, $active, $hover) {
  @extend %button;

  background-color: $color;
  color: $white;
  &:disabled {
    background-color: $charcoal-0;
  }
  &:not(:disabled) {
    &:hover,
    &:focus {
      background-color: $hover;
    }
    &:active {
      background-color: $active;
    }
  }
}

@mixin plain-button($color, $active, $hover, $border: 'false') {
  @extend %button;

  color: $color;
  @if $border == true {
    border: 2px solid $color;
    > span {
      top: 1px;
    }
  }
  &:disabled {
    color: $charcoal-0;
    @if $border == true {
      border-color: $charcoal-0;
    }
  }
  &:not(:disabled) {
    &:hover,
    &:focus {
      color: $hover;
      @if $border == true {
        border-color: $hover;
      }
    }
    &:active {
      color: $active;
      @if $border == true {
        border-color: $active;
      }
    }
  }
}

.Button.default {
  @include fill-button(
    $carrot-2,
    $carrot-3,
    $carrot-3
  );
}
.Button.default-alternate {
  @include fill-button(
    $carrot-2,
    $carrot-3,
    $carrot-3
  );
}
.Button.default-disabled {
  @include fill-button($charcoal-0, $charcoal-0, $charcoal-0);
}
.Button.plain {
  @include plain-button(
    $carrot-2,
    $carrot-3,
    $carrot-3
  );
}
.Button.plain-alternate {
  @include plain-button(
    $carrot-2,
    $carrot-3,
    $carrot-3
  );
}
.Button.plain-text {
  @include plain-button($charcoal-1, $charcoal-1, $charcoal-1);
}
.Button.bordered {
  @include plain-button(
    $carrot-2,
    $carrot-3,
    $carrot-3,
    $border: true
  );
}
.Button.bordered-alternate {
  @include plain-button(
    $carrot-2,
    $carrot-3,
    $carrot-3,
    $border: true
  );
}
.Button.link {
  border-bottom: 1px solid $carrot-2;
  color: $carrot-2;
  text-decoration: none;
  letter-spacing: inherit;
  font-size: inherit;
  line-height: inherit;
  cursor: pointer;
  &:hover {
    border-color: $carrot-3;
    color: $carrot-3;
  }
}
.Button.link-alternate {
  border-bottom: 1px solid $carrot-2;
  color: $carrot-2;
  text-decoration: none;
  letter-spacing: inherit;
  font-size: inherit;
  cursor: pointer;
  &:hover {
    border-color: $carrot-3;
    color: $carrot-3;
  }
}

// Fix `top` hack in button-label for not-serif texts
.Text.serif .Button.link > .Button-label,
.Text.serif .Button.link-alternate > .Button-label {
  top: 0;
}

.Button.rounded {
  @include plain-button($charcoal-3, $charcoal-3, $charcoal-3);

  & {
    padding-right: 20px;
    padding-left: 20px;
    height: 30px;
    border: 1px solid $charcoal-3;
    border-radius: 20px;
    background: white;
    text-transform: none;
    font-weight: normal;
    font-size: 12px;
    line-height: 2em;
  }

  &:hover {
    background-color: $oyster-0;
  }
  > .Button-label {
    position: relative;
    top: 1px !important;
  }
}

.Button.inverted {
  @include plain-button($charcoal-3, $charcoal-3, $charcoal-3);

  & {
    background-color: white;
  }
}
.Button.facebook {
  @include plain-button($white, $white, $white);

  & {
    padding: 0;
    border-radius: 2px;
    background-color: ($facebook);
  }

  &:hover {
    background-color: color.adjust($facebook, $lightness: 6%, $space: hsl);
    color: white;
  }
  &.Button--is-loading {
    background-color: $white;
  }
  &[disabled]:not(.Button--is-loading),
  &[disabled]:not(.Button--is-loading):hover {
    background-color: ($facebook);
    opacity: 0.5;
  }
  > .Button-label > svg {
    position: relative;
    top: 0;
  }
}
