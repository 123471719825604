.container {
  position: relative;
  z-index: 0;
}

.image {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  z-index: -1;
}

.svg-container {
  width: 100%;
}

.svg {
  width: 100%;
}

.no-recipe {
  filter: grayscale(100%);
}
