.iconSelected {
  text-align: center;
}

.iconSelected img {
  height: 144px;
  margin-top: var(--margin-lg);
  max-width: 230px;
  object-fit: contain;
  object-position: bottom;
}

.selectorGroup {
  padding: 0;
}

.picture {
  margin-top: var(--margin-lg);
  max-width: 230px;
  object-fit: contain;
  object-position: bottom;
}

@media (--viewport-lg) {
  .iconSelected {
    display: none;
  }
}
