.container {
    display: flex;
    align-items: center;
    justify-content: flex-end;
    text-align: center;
}

.container a.active {
    color: var(--carrot-2);
}
