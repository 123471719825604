@use "sass:color";
@use 'constants.scss' as *;

/* Reset the button style */
@mixin button-reset() {
  overflow: visible;
  box-sizing: content-box;
  padding: 0;
  border: 0;
  background: none;
  color: inherit;
  font: inherit;
  line-height: normal;

  appearance: none;
  &::-moz-focus-inner,
  &::-moz-focus-inner {
    padding: 0;
    border: 0;
  }
}

/* Reset the list style */
@mixin list-reset() {
  list-style: none;
  margin: 0;
  padding: 0;
  li {
    margin: 0;
    padding: 0;
  }
}

/* Indent by $margin all lines in a text except the first line */
@mixin indent($margin) {
  padding-left: $margin;
  text-indent: -$margin;
}

/* Device related mixins */
@mixin small-screens($max-width: $max-mobile-width) {
  @media screen and (max-width: $max-width) {
    @content;
  }
}
@mixin medium-screens() {
  @media screen and (min-width: $min-desktop-width) and (max-width: 960px) {
    @content;
  }
}
@mixin wide-screens($min-width: $min-desktop-width) {
  @media screen and (min-width: $min-width) {
    @content;
  }
}

/* Emulates the browsers default outline */
@mixin outline() {
  outline: solid 2px rgba(0, 103, 244, 0.247);
  outline: auto 5px -webkit-focus-ring-color;
}

/* Make an element unselectable */
@mixin unselectable() {
  -khtml-user-select: none; /* Konqueror */
  -webkit-touch-callout: none; /* iOS Safari */
  -webkit-user-select: none; /* Chrome/Safari/Opera */
  -moz-user-select: none; /* Firefox */
  -ms-user-select: none; /* Internet Explorer/Edge */
  user-select: none; /* Non-prefixed version, currently not supported by any browser */
}

/* Create a background parallax effect */
@mixin parallax($height: 100vh) {
  height: $height;
  background-attachment: fixed;
  background-position: center;
  background-size: cover;
  background-repeat: no-repeat;
}

@function shade($color, $percent) {
  @return color.mix(#000000, $color, $percent);
}
