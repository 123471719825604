/**
Copy of the Sass constants from the legacy corgi library.

Please do not import this file in your CSS. Instead, use the CSS variables that
are already set when using corgi-x.
 
See http://corgi-x.tfd.engineering/system/colors. 
**/

$margin-xxs: 2px;
$margin-xxs-value: 2;
$margin-xs: 6px;
$margin-xs-value: 6;
$margin-sm: 12px;
$margin-sm-value: 12;
$margin-md: 24px;
$margin-md-value: 24;
$margin-lg: 36px;
$margin-lg-value: 36;
$margin-xl: 48px;
$margin-xl-value: 48;
$margin-xxl: 72px;
$margin-xxl-value: 72;
$margin-3xl: 96px;
$margin-3xl-value: 96;
$margin-4xl: 120px;
$margin-4xl-value: 120;
$kale-0: #e5efe7;
$kale-1: #b5d3ba;
$kale-2: #417c67;
$kale-3: #173b33;
$blueberry-0: #e6ecf5;
$blueberry-1: #b7c6e6;
$blueberry-2: #385a9d;
$blueberry-3: #03184e;
$carrot-0: #fff4f1;
$carrot-1: #ffd9cc;
$carrot-2: #f2674b;
$carrot-3: #d2563d;
$charcoal-0: #c2c3c5;
$charcoal-1: #969799;
$charcoal-2: #717377;
$charcoal-3: #39383c;
$chickpea-0: #fffaf3;
$chickpea-1: #fff3e1;
$chickpea-2: #f2e1c8;
$chickpea-3: #d3bfa2;
$oyster-0: #f6f5f3;
$oyster-1: #efedea;
$oyster-2: #dbd6d1;
$oyster-3: #c6bfb7;
$tfd-logo: #f5694d;
$white: #ffffff;
$error-red: #e31c3d;
$twitter: #1da1f2;
$facebook: #3b5998;
$instagram: #6ba5d5;
$xs: 0px;
$xs-value: 0;
$sm: 375px;
$sm-value: 375;
$md: 710px;
$md-value: 710;
$lg: 1024px;
$lg-value: 1024;
$xl: 1400px;
$xl-value: 1400;
$border-label-text-offset: 4px;
$border-label-text-offset-value: 4;
$border-label-padding: 12px;
$border-label-padding-value: 12;
$border-label-height: 50px;
$border-label-height-value: 50;
$border-label-radius: 4px;
$border-label-radius-value: 4;
$checkbox-size: 26px;
$checkbox-size-value: 26;
$input-padding: var(--margin-sm);
$default-arrow-button-size: 48px;
$default-arrow-button-size-value: 48;
$styled-select-height: 50px;
$styled-select-height-value: 50;
$checkbox-input-size: 26px;
$checkbox-input-size-value: 26;
$modal-top-padding: 58px;
$modal-top-padding-value: 58;
$modal-top-padding-lg: 80px;
$modal-top-padding-lg-value: 80;
$radio-input-size: 32px;
$radio-input-size-value: 32;
$animation-standard: 300ms ease-in-out;
$animation-standard-value: 300;
$animation-standard-timing-function: ease-in-out;
$animation-standard-duration: 300ms;
$animation-standard-duration-value: 300;
$page-margin-xs: 10px;
$page-margin-xs-value: 10;
$page-margin-sm: 30px;
$page-margin-sm-value: 30;
$page-margin-md: auto;
$page-margin-lg: 40px;
$page-margin-lg-value: 40;
$page-margin-xl: auto;
$gutter-lg: 20px;
$gutter-lg-value: 20;
$gutter-xl: 30px;
$gutter-xl-value: 30;
$column-xs: 100%;
$column-xs-value: 100;
$column-sm: 100%;
$column-sm-value: 100;
$column-md: 100%;
$column-md-value: 100;
$column-lg: 300px;
$column-lg-value: 300;
$column-xl: 375px;
$column-xl-value: 375;
$grid-column-gap-sm: 12px;
$grid-column-gap-sm-value: 12;
$grid-column-gap-md: 24px;
$grid-column-gap-md-value: 24;
$black: #000000;
$blue: #4141a5;
$pale-red: #d64c4c;
$pale-salmon: #ffb3a2;
$pinkish-orange: #ff764d;
$salmon: #ff8d6a;
$silver: #d0d1d1;
$slate-grey: #5b5e62;
$very-light-pink: #f9f4ee;
$very-light-pink-two: #fcfaf6;
$weird-green: #4cd6a4;
$white-two: #f9f9f9;
$z-index-modal: 1000000;
$z-index-modal-value: 1000000;
$z-index-app-bar: 9001;
$z-index-app-bar-value: 9001;
$article: tiempos, serif;
$body: neue-haas-grotesk-text, sans-serif;
$heading-sans: neue-haas-grotesk-display, sans-serif;
