@use '@farmersdog/corgi-x/src/styles/constants-legacy.scss' as *;

.xs {
  margin-top: $margin-xxl;
}

.lg {
  margin: $margin-3xl auto 0;
}

.title {
  text-align: center;
}

.xs .gallery {
  margin-top: $margin-md;
}

.lg .gallery {
  margin-top: $margin-xl;
}

.caption {
  margin: 0 $margin-md;
}

.accessibilityTheme.gallery {
  margin-bottom: $margin-md;
}

.accessibilityTheme.title {
  padding: $margin-md 0;
}

.accessibilityThemeLink {
  color: $kale-3 !important;
}
