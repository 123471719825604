.wrapper {
  margin: auto;
  max-width: 1440px;
  width: 100%;
  display: flex;
  justify-content: center;
}

.factCard {
  background-color: var(--kale-1);
  margin: 0 var(--tfd-spacing-md) !important;
  padding: var(--tfd-spacing-lg) var(--tfd-spacing-md);
  text-align: center;
}

.button {
  font-size: 22px !important;
}

.destopBreakline {
  display: none;
}

@media (--viewport-lg) {
  .wrapper {
    margin-bottom: 144px;
  }

  .factCard {
    margin: 0 auto;
    max-width: 994px;
    padding: var(--margin-xl) 168px;
  }

  .destopBreakline {
    display: block;
  }

  .icon {
    width: 90px;
  }

  .heading {
    font-weight: bold;
  }
}
