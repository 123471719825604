.innerContainer {
  display: flex;
  position: fixed;
  left: 0;
  bottom: 0;
  width: 100vw;
  background-color: var(--white);
  box-shadow: 0px 0px 5px rgba(0, 0, 0, 0.25);
}

.button {
  display: block;
  margin: var(--margin-lg) auto;
}

.additionalWhiteSpace {
  margin-top: var(--margin-3xl);
}

@media (--viewport-md) {
  .innerContainer {
    height: 120px;
  }

  .additionalWhiteSpace {
    margin-top: 120px;
  }

  .button {
    position: relative;
    margin-top: var(--margin-md);
    margin-bottom: var(--margin-xl);
  }
}
