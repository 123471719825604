.InlineInput {
  margin: 0;
  appearance: none;
  display: inline-block;
  padding: 8px 8px 0;
  text-align: center;
  color: var(--carrot-2);
  border: 0;
  border-bottom: 4px dotted var(--charcoal-1);
  outline: none;
  background-color: transparent;
}

.InlineInput:focus {
  outline: none;
  border-bottom: 4px solid var(--carrot-3);
}

.InlineInput:hover {
  border-bottom-color: var(--carrot-3);
}

.InlineInput::placeholder {
  font-weight: 300;
}

.InlineInput::selection {
  color: var(--white);
  background-color: var(--carrot-3);
}

.label {
  display: inline-block;
  position: absolute;
  left: -10000px;
  top: auto;
  width: 1px;
  height: 1px;
  overflow: hidden;
}
