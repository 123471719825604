.init {
  opacity: 0;
}

.entered {
  opacity: 1;
}

.exited {
  opacity: 0;
}

.unmounted {
  opacity: 0;
}
