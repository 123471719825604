@use '@farmersdog/corgi-x/src/styles/constants-legacy.scss' as *;

.availability {
  cursor: default;
  margin-left: var(--tfd-spacing-sm);
}

.list {
  margin: 0;
  padding: 0;
  list-style: none;
}

.socialList .item {
  margin-right: var(--margin-md);
}

.socialList .item .icon {
  margin: 0;
}

.contactLink, .socialList {
  display: flex;
}

.contactLink .icon {
  width: 22px;
  height: 22px;
  margin-right: var(--margin-xxs);
  vertical-align: middle;
}
