.root {
  box-sizing: border-box;
}

.root *,
.root *:before,
.root *:after {
  box-sizing: inherit;
}

.root body > img {
  /** Ensures marketing pixels remain hidden */
  display: none;
}
