@use '@farmersdog/corgi-x/src/styles/constants-legacy.scss' as *;

.xs {
  margin-top: $margin-md;
  margin-bottom: $margin-lg;
}

.lg {
  margin-top: $margin-xl;
  margin-bottom: -$margin-xxl;
}

.header {
  margin: 0;
  text-align: center;
}

.hero {
  margin: $margin-lg 0;
}

.hero-image {
  object-fit: cover;
  width: 100%;
  height: 100%;
  display: block;
}

.xs .section + .section {
  margin-top: $margin-xxl;
}

.lg .section + .section {
  margin-top: $margin-3xl;
}

.section.accessibilityTheme {
  margin-top: $margin-xl;
}

.lg .kibble-bull {
  display: flex;
  align-items: center;
}

.xs .kibble-bull-content {
  margin-top: $margin-xl;
}

.lg .kibble-bull-content {
  margin-top: 0;
}
