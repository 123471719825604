:root {
  --radio-input-size: 32px;
}

.radio {
  position: relative;
  display: block;
  height: var(--radio-input-size);
  width: var(--radio-input-size);
  background-color: var(--white);
  border: 2px solid var(--charcoal-1);
  border-radius: 50%;
  transition-timing-function: var(--animation-standard-timing-function);
  transition-duration: var(--animation-standard-duration);
  transition-property: border-color, background-color;
}

.radio:after {
  content: '';
  opacity: 0;

  position: absolute;
  top: 0px;
  left: 0px;
  right: 0px;
  bottom: 0px;

  border: 3px solid var(--white);
  border-radius: 50%;

  transition-timing-function: var(--animation-standard-timing-function);
  transition-duration: var(--animation-standard-duration);
  transition-property: opacity;
}

input:checked ~ .radio,
.checked {
  background-color: var(--carrot-2);
  border-color: var(--charcoal-1);
}

input:checked ~ .radio:after,
.checked:after {
  opacity: 1;
}

input:checked ~ .radio.disabled {
  background-color: var(--charcoal-0);
  border-color: var(--charcoal-0);
}

.selector > input:focus ~ div > span.label {
  border-radius: 1px;
  outline: auto 5px Highlight; /* Auto outline for Firefox */
  outline: auto 5px -webkit-focus-ring-color; /* Auto outline for Chrome */
  outline-offset: 4px;
}
