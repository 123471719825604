.dogContainer {
  text-align: center;
}

@media (--viewport-lg) {
  .dogContainer {
    text-align: left;
  }
}

.dogContainer svg {
  margin-top: var(--tfd-spacing-md);
  margin-bottom: var(--tfd-spacing-lg);
  width: 100%;
  max-width: 200px;
}

.menu {
  margin-top: var(--tfd-spacing-sm);
}
