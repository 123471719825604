.section {
  width: 100%;
  padding: 0 var(--margin-md);
}

.container {
  margin: 0 auto;
  max-width: 1266px;
}

.section img {
  max-width: 100%;
}

.button {
  display: block !important;
  margin: var(--margin-xl) auto 0 auto;
}
