@use '@farmersdog/corgi-x/src/styles/constants-legacy.scss' as *;

.Testimonial {
  margin: 0;
  padding: 1.5rem;
  border-radius: 8px;
  background: $white;
  box-shadow: 0 3px 5px rgba(0, 0, 0, 0.1);

  &-star {
    width: 24px;
    height: 24px;

    polygon {
      fill: $carrot-2;
    }

    &:first-child {
      margin-left: -4px;
    }
  }

  &-text {
    display: inline-block;
    margin-top: $margin-sm;
    margin-bottom: 0;
    vertical-align: middle;
  }

  &-starcheck {
    display: inline-block;
    width: 16px;
    height: 16px;
    vertical-align: bottom;
    transform: translate(6px, -6px);

    fill: $instagram;
  }
}
