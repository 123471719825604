.recommendedRecipesList,
.pricePerDay,
.planDescription {
  margin: 0;
}

.selectPlanButton {
  text-decoration: underline 1px;
}

.seeDetailsButton {
  display: flex !important;
}

.ctaContainer,
.recipesInfoContainer {
  display: flex;
  flex-direction: column;
}

.recipeStack {
  display: none;
}

.divider {
  display: none;
}

.strikeoutPrice {
  text-decoration-thickness: 1px;
}

.newLine {
  display: block;
}

.infoContainer {
  text-align: center;
  row-gap: var(--tfd-spacing-xs);
}

.planCopyContainer {
  display: flex;
  flex-direction: column;
  row-gap: var(--tfd-spacing-xs);
  align-items: center;
  padding: 0 var(--tfd-spacing-sm);
}

.ctaContainer {
  flex-wrap: wrap;
  row-gap: var(--tfd-spacing-sm);
  margin-top: var(--tfd-spacing-sm);
  width: 100%;
}

.seeDetailsButton {
  text-decoration: underline 1px;
  margin-left: var(--tfd-spacing-xs);
}
/* This makes the bottom of the Nutrition Facts underline flush with the Recommended Recipe List text*/
.seeDetailsButtonContainer {
  top: 2px;
  vertical-align: top;
}

.recipesInfoContainer {
  row-gap: var(--tfd-spacing-xxs);
  margin-top: var(--tfd-spacing-xs);
}

.mobileRecipeStack {
  padding: 0;
}

.singleRecipeCopyHeading {
  display: flex;
  justify-content: center;
  flex-direction: row;
  flex-wrap: wrap;
  width: 250px;
  margin: 0;
}

.wave {
  width: 100vw;
  position: absolute;
  left: 0;
}

.footer {
  margin-top: 0px !important;
  min-height: 600px;
}

@media (--viewport-md) {
  .recipePageTitle {
    font-size: 40px;
    line-height: 48px;
  }

  .divider,
  .recipeStack {
    display: flex;
  }

  .ctaContainer {
    align-items: flex-start;
    max-width: 294px;
    margin-top: 0;
  }

  .pricePerDay {
    font-size: 21px;
  }

  .infoContainer {
    row-gap: var(--tfd-spacing-md);
    text-align: left;
  }

  .mobileRecipeStack {
    display: none;
  }

  .planCopyContainer {
    align-items: flex-start;
    margin: 0;
    padding: 0;
  }

  .singleRecipeCopyHeading {
    width: 100%;
    justify-content: flex-start;
    flex-wrap: nowrap;
  }

  .planDescription {
    padding: 0;
    margin-bottom: 0;
  }

  .recipesInfoContainer {
    row-gap: var(--tfd-spacing-sm);
    margin-top: -10px;
  }

  .singleRecipePlanDescriptionOnDesktop {
    padding: 0;
    margin-bottom: 0;
    font-size: 16px;
    line-height: var(--tfd-article-16-line-height);
  }

  .footer {
    min-height: 450px;
  }
}

.hidden {
  display: none;
}
