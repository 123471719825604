.ContentPage .ActivityIndicator {
  display: block;
  margin: 2em;
  margin-top: 5em;
  text-align: center;
}
.ContentPage-header {
  padding-top: 2em;
  text-align: center;
}

.ContentPage-body {
  padding: 2em 0;
  min-height: 100vh;
  li,
  p {
    font-family: var(--tfd-font-family-serif);
    font-size: 16px;
    line-height: var(--tfd-article-16-line-height);
    color: var(--charcoal-3);
    -webkit-font-smoothing: antialiased;
  }
  th,
  td {
    font-family: var(--tfd-font-family-serif);
    font-size: 16px;
    line-height: var(--tfd-article-16-line-height);
    color: var(--charcoal-3);
    padding: var(--tfd-spacing-sm);
    -webkit-font-smoothing: antialiased;
  }
  td {
    vertical-align: top;
    border: 1px solid var(--oyster-0);
    border-top: none;
  }
  td + td {
    border-left: none;
  }
  thead {
    background-color: var(--oyster-0);
  }
  h1:first-child {
    text-align: center;
  }
  h1:first-child + p {
    margin-bottom: 2em;
    padding-bottom: 2em;
    border-bottom: 1px solid var(--oyster-0);
    text-align: center;
    font-style: italic;
  }
  h1,
  h2,
  h3,
  h4,
  h5,
  h6 {
    font-family: var(--tfd-font-family);
    font-stretch: normal;
    color: var(--kale-3);
    font-weight: 700;
    letter-spacing: 0.5px;
  }

  h1 {
    font-size: 40px;
    line-height: var(--tfd-heading-40-line-height);
  }

  h2 {
    font-size: 28px;
    line-height: var(--tfd-heading-28-line-height);
  }

  h3 {
    font-size: 22px;
    line-height: var(--tfd-heading-22-line-height);
  }

  h4 {
    font-size: 16px;
    line-height: var(--tfd-heading-16-line-height);
  }

  h5,
  h6 {
    font-size: 12px;
    line-height: 1.33;
  }
}
