.subheader {
  padding-top: var(--tfd-spacing-xs);
  margin-bottom: var(--tfd-spacing-sm);
}

.desktop {
  display: none;
}

@media (--viewport-md) {
  .mobile {
    display: none;
  }

  .desktop {
    display: block;
  }
}
