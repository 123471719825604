.section {
  position: relative;
}

.grid {
  height: 100%;
  max-width: 1300px;
}

.foodPack {
  width: 275px;
}

.cta,
.packContainer {
  max-width: fit-content;
}

.gridItem,
.packContainer,
.copyContainer,
.section {
  display: flex;
  flex-direction: column;
}

.title {
  font-size: 52px;
  line-height: 56px;
  margin-top: 36px;
}

.subtitle {
  font-size: 16px;
  line-height: 22px;
}

.packContainer {
  display: none;
}

.cta {
  margin: 0 auto;
}

.mobileFoodPackImage {
  left: 11px;
  width: 127px;
  position: relative;
  vertical-align: bottom;
}

.mobileFoodPackContainer {
  margin-top: 34px;
  height: fit-content;
}

.mobileFoodPackContainer picture {
  height: 184px;
}

@media (--viewport-lg) {
  .section {
    height: 725px;
    justify-content: center;
  }

  .packContainer {
    display: flex;
    justify-content: center;
  }

  .title {
    font-size: 68px;
    line-height: 85px;
    margin-top: var(--margin-4xl);
  }

  .subtitle {
    font-size: 24px;
    line-height: 30px;
  }

  .copyContainer {
    align-self: end;
    margin-bottom: var(--margin-4xl);
  }
  .cta {
    margin: 0;
  }
  .mobileFoodPackContainer {
    display: none;
  }
}

/* We want a different size in this specific breakpoint which corgi wont support */
@media (min-width: 1150px) {
  .title {
    font-size: 76px;
  }
}
