.container {
  max-width: 100%;
  margin-bottom: 36px;
}

.buttons {
  display: flex;
  flex-direction: row;
  justify-content: center;
  padding: 0 12px;
  margin-bottom: 24px;
}

.button {
  margin: 0 12px;
  opacity: 0.36;
  background: none;
  border: none;
  cursor: pointer;

  &.active {
    opacity: 1;
  }
}

.slide {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  text-align: center;
  margin: 0;
}

.name {
  margin-bottom: 12px;
}

.quote {
  margin: 0;
  align-self: stretch;
}
