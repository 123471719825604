.container {
  position: relative;
}

.withMobilePadding {
  padding: 0 var(--margin-md) var(--margin-xl) var(--margin-md);
}

@media (--viewport-lg) {
  .withMobilePadding,
  .container {
    padding: 0;
  }
}
