.inline {
  display: inline;
  white-space: pre-wrap;
}

.block {
  display: block;
}

.hiddenNode {
  visibility: hidden;
}

@media (--viewport-xs) {
  .block + .block {
    line-height: var(--tfd-spacing-xxl);
  }
}

@media (--viewport-lg) {
  .block + .block {
    line-height: var(--tfd-spacing-3xl);
  }
}
