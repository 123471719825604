.field {
  text-align: left;
}

.field + .field {
  margin-top: 20px;
}

.submitButton {
  width: 100%;
  margin-top: 36px;
}

.success {
  text-align: center;
  margin: 10px 0;
}

.error {
  text-align: left;
  margin-left: 12px;
  color: var(--error-red);
}

/* fixes hidden overflow cutting off field labels */
.slider {
  margin-top: -5px;
  padding-top: 5px;
}

.loginLink {
  display: block;
  margin-top: 12px;
}

.form {
  margin-bottom: var(--margin-lg);
}
