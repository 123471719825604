@use '@farmersdog/corgi-x/src/styles/constants-legacy.scss' as *;

.head {
  margin: 0;
  text-align: center;
}

.body {
  margin: 0;
  margin-top: $margin-sm;
  text-align: center;
}

.xs .hero {
  margin: $margin-xl 0;
}

.lg .hero,
.xl .hero {
  margin: $margin-xxl 0;
}

.video-wrapper {
  height: 100%;
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  // Required to render activity indicator correctly
  min-width: 200px;
}

.xl .video-wrapper {
  min-width: 1440px;
}
