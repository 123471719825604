.container {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
}

.label {
  text-align: center;
}

.input {
  width: 22px;
  height: 22px;
}

.large-input {
  width: 36px;
  height: 36px;
}

.icon {
  display: none;
}

.icon img,
.iconSmall img {
  height: 144px;
}

@media (--viewport-lg) {
  .icon {
    display: block;
  }

  .iconSmall {
    display: none;
  }
}
