:root {
  --numeric-input-width: 76px;
}

.input {
  margin-left: var(--margin-sm);
  margin-right: var(--margin-sm);
}

.phone {
  margin-top: var(--margin-lg);
}

.zip {
  margin-top: var(--margin-lg);
  margin-bottom: var(--margin-md);
}

.birthdayAmount {
  max-width: var(--numeric-input-width);
}

.firstName {
  max-width: 270px;
  margin-top: var(--margin-lg);
}

.targetWeight {
  max-width: var(--numeric-input-width);
}

@media (--viewport-md) {
  .zip,
  .phone {
    margin-top: 0;
    margin-bottom: 0;
  }
}
