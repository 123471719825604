@use '@farmersdog/corgi-x/src/styles/constants-legacy.scss' as *;

.container {
  width: 300px;
  height: 100%;
  padding: 0 10px 48px 10px;

  display: flex;
  flex-direction: column;
  align-items: center;
  position: relative;
}

@media (min-width: $sm) {
  .container {
    width: 315px;
    padding: 0 24px 48px 24px;
  }
}

@media (min-width: $lg) {
  .container {
    width: 375px;
    padding: 0 30px 51px 30px;
  }
}

.card {
  margin-top: 36px;
}

.initials {
  background: var(--kale-3);
  height: 72px;
  width: 72px;

  display: flex;
  justify-content: center;
  align-items: center;
}

.initialsMask {
  position: absolute;
  top: 0;
  transform: translateY(-50%);
}

.content {
  margin-top: 62px;
  flex: 1;
  text-align: center;
  align-self: stretch;
}

.name {
  margin-bottom: 13px;
}

.link {
  margin-bottom: 12px;
}

.comment {
  margin: 0;
  padding-bottom: 24px;
}

.accessibilityThemeReadMore {
  color: var(--kale-3);
  text-decoration: underline;
}

.accessiblilityThemeComment {
  padding-bottom: var(--spacing-xxl);
  padding-top: var(--spacing-sm);
}
