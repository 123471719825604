.container {
  background-color: var(--kale-3);
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  text-align: center;
  padding: 48px 10px 72px 10px;
}

.accessibilityThemeContainer {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  text-align: center;
  padding: 72px 10px;
}

.heading {
  margin-top: 16px;
  margin-bottom: 48px;
}

@media (--viewport-lg) {
  .container {
    padding: 96px 30px;
  }

  .accessibilityThemeContainer {
    padding: 96px 30px;
  }

  .heading {
    margin-bottom: 72px;
  }
}
