@use 'sass:color';

@use '@farmersdog/corgi-x/src/styles/constants-legacy.scss' as *;
@use 'styles/mixins.scss' as *;

.ReviewsSlider {
  position: relative;
  padding: 1.5rem 0;

  &-controls {
    position: absolute;
    top: 0;
    right: 0;
    bottom: 0;
    left: 0;

    width: 100%;
    height: 100%;
  }

  &-buttonlanding {
    overflow: visible;
    padding: 0 4rem;
    min-width: 200px;
    width: 15%;
    height: 100%;
    transition: 250ms ease;
    cursor: pointer;

    .CircleButton.Button.default-alternate {
      background-color: color.adjust($charcoal-1, $alpha: -0.4, $space: hsl);
    }
    &:hover {
      .CircleButton.Button.default-alternate {
        background-color: $carrot-2;
      }
    }
  }

  .Slider-slide {
    /** bugfix to enforce 100% height on children in safari */
    display: flex;
    padding: 0.25rem 1rem;
  }

  .Slider-slide > * {
    /** bugfix to enforce card widths on edge browser */
    width: 100%;
  }

  .Slider {
    padding: 0 0.5rem;
  }
}
