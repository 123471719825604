@use '@farmersdog/corgi-x/src/styles/constants-legacy.scss' as *;

.container {
  width: 100%;
  display: flex;
  flex-wrap: wrap;
  margin-top: $margin-md;
}

.item {
  margin: 0;
}

.row {
  display: flex;
  flex-wrap: wrap;
  width: 100%;
}

.row + .row {
  margin-top: $margin-lg;
}

.item + .item {
  margin-left: $margin-xl;
}

.left {
  justify-content: flex-start;
  text-align: left;
}

.center {
  justify-content: center;
  text-align: center;

  .item:first-child {
    margin-left: auto;
  }

  .item:last-child {
    margin-right: auto;
  }
}
