.instagram {
  overflow: hidden;
  border-radius: var(--border-radius-sm) * 2;
  background: #ffffff;
  box-shadow: 0 3px 5px rgba(0, 0, 0, 0.1);
  margin: 0;
}

.imageRatio {
  position: relative;
}

.imageRatio:before {
  display: block;
  width: 100%;
  content: '';
}

.imageRatio img {
  bottom: 0;
  top: 0;
  right: 0;
  display: block;
  height: 100%;
  left: 0;
  object-fit: cover;
  width: 100%;
}

.content {
  padding: 1rem;
  padding-top: 0;
  text-align: left;
}

.userName {
  display: inline;
}
