.legalDetailsContainer {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  border-top: 4px dotted var(--charcoal-1);
  margin: 0 var(--page-margin-sm) var(--margin-4xl);
}

.copyright {
  flex: 1;
  display: flex;
  flex-direction: column;
  align-items: flex-start;
}

.copyrightText {
  margin-top: var(--margin-xl);
  margin-bottom: var(--margin-sm);
}

.longLiveDogs {
  margin-bottom: var(--margin-sm);
  padding: 0;
  border: none;
}

.legalDetails {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  margin: 0 0 var(--margin-lg) 0;
  padding-left: 0;
  list-style-type: none;
}

.legalDetails .linkWrapper {
  margin-left: 0;
  margin-bottom: var(--margin-sm);
  text-align: left;
}

.legalDetails .link {
  color: var(--charcoal-3);
  fill: var(--charcoal-3);
}

@media (--viewport-lg) {
  .legalDetailsContainer {
    flex-direction: row;
    margin: 0 var(--margin-4xl) var(--margin-xl);
  }

  .copyright {
    margin-bottom: 0;
    align-self: flex-start;
    text-align: left;
  }

  .legalDetails {
    margin: var(--margin-xl) 0 0;
    text-align: right;
    align-items: flex-end;
  }
}
