.reviewSlider {
  position: relative;
  padding: 1.5rem 0;
  max-width: 100vw;
}

.reviewSliderControls {
  display: flex;
  position: absolute;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  width: 100%;
  height: 100%;
  display: flex;
  align-items: center;
}

.buttonLanding {
  overflow: visible;
  padding: 0 4rem;
  min-width: 200px;
  width: 15%;
  height: 100%;
  transition: 250ms ease;
  cursor: pointer;
  display: flex;
  align-items: center;
  position: relative;
}

.touchInterface {
  margin-top: 1rem;
  display: flex;
  justify-content: center;
}

.slide {
  padding: 0.25rem 1rem;
  /** bugfix to enforce 100% height on children in safari */
  width: 100%;
}
