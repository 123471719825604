
.dot {
  background: var(--charcoal-3);
  border-radius: 50%;
  height: 5px;
  width: 5px;
}

.line {
  flex: 1;
  border-bottom: 1px solid black;
}

.lineAndDot {
  display: flex;
  flex: 1;
  align-items: center;
  margin-left: var(--margin-sm);
}

.lineAndDot.flip {
  margin-left: var(--margin-md);
  margin-right: var(--margin-sm);
}
