/**
  * This is a generated file. Do not modify directly.
  * Instead use generateKeyframes.js
  */
@keyframes spring-down-in {
  0% {
    transform: translate(0px, -12px);
  }
  2% {
    transform: translate(0px, -9.93px);
  }
  4% {
    transform: translate(0px, -7.27px);
  }
  6% {
    transform: translate(0px, -4.59px);
  }
  8% {
    transform: translate(0px, -2.27px);
  }
  10% {
    transform: translate(0px, -0.48px);
  }
  12% {
    transform: translate(0px, 0.72px);
  }
  14% {
    transform: translate(0px, 1.38px);
  }
  16% {
    transform: translate(0px, 1.62px);
  }
  18% {
    transform: translate(0px, 1.55px);
  }
  20% {
    transform: translate(0px, 1.29px);
  }
  22% {
    transform: translate(0px, 0.96px);
  }
  24% {
    transform: translate(0px, 0.61px);
  }
  26% {
    transform: translate(0px, 0.31px);
  }
  28% {
    transform: translate(0px, 0.08px);
  }
  30% {
    transform: translate(0px, -0.08px);
  }
  32% {
    transform: translate(0px, -0.17px);
  }
  34% {
    transform: translate(0px, -0.21px);
  }
  38% {
    transform: translate(0px, -0.17px);
  }
  40% {
    transform: translate(0px, -0.13px);
  }
  42% {
    transform: translate(0px, -0.08px);
  }
  44% {
    transform: translate(0px, -0.04px);
  }
  46% {
    transform: translate(0px, -0.01px);
  }
  100% {
    transform: translate(0px, 0px);
  }
}
