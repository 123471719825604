.container {
  background-color: var(--kale-3);
  padding: 24px 10px 72px 10px;
  display: flex;
  align-items: center;
  justify-content: center;
}

.content {
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  max-width: 750px;
  justify-content: center;
  align-items: center;
  text-align: center;
}

.review {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  width: 315px;
  margin-top: 48px;
}

.quote {
  margin: 0;
  align-self: stretch;
  margin-top: 14px;
}

.logo {
  width: 100%;
}

.cbsNews {
  height: 24px;
  width: 162px;
}

.vogue {
  width: 92px;
  height: 24px;
}

.fastCompany {
  width: 157px;
  height: 24px;
}

.todayShow {
  width: 155px;
  height: 24px;
}

.kaleBackground {
  background-color: var(--kale-0);
}

@media (--viewport-sm) {
  .container {
    padding-left: 30px;
    padding-right: 30px;
  }
}

@media (--viewport-lg) {
  .container {
    padding: 0 30px 120px 30px;
  }

  .content {
    justify-content: space-between;
  }

  .review {
    margin-top: 120px;
  }
}
