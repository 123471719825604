.label {
  border: 0;
  clip: rect(0 0 0 0);
  height: 1px;
  margin: -1px;
  overflow: hidden;
  padding: 0;
  position: absolute;
  width: 1px;
}

.wrapper {
  display: inline-block;
}

.container {
  position: relative;
  display: inline-block;
  box-sizing: border-box;
  line-height: 1;

  width: auto;
  max-height: var(--form-field-height);
  border: 0;
  border-bottom: 4px dotted var(--charcoal-1);

  margin: 0 var(--margin-sm);

  background: var(--white);
}

.focused {
  border-bottom: 4px solid var(--carrot-3);
}

.container ::-webkit-scrollbar {
  width: 14px;
  border: 1px var(--carrot-3);
  border-top: none;
  border-bottom: none;
  /* equivalent to scss transparentize(var(--carrot-1), 0.4); */
  background-color: rgba(255, 193, 181, 0.6);

  -webkit-appearance: none;
}

.container ::-webkit-scrollbar-thumb {
  min-height: 48px;
  border: 3px solid transparent;
  border-radius: 9px;
  background-color: var(--carrot-3);
  background-clip: content-box;
}

.button {
  display: flex;
  cursor: pointer;
  position: relative;
  padding-left: 12px;
  justify-content: space-between;
  width: 100%;
  min-width: inherit;
}

.button:focus {
  outline: none;
}

.buttonText,
.buttonTextPlaceholder {
  flex: 1;
}

.buttonTextPlaceholder {
  color: var(--charcoal-1);
}

.dropdownIcon {
  width: 24px;
  display: flex;
  align-items: center;
  justify-content: center;
}

.menu {
  list-style: none;
  margin: 0;
  padding: 0;
  text-align: center;
  position: absolute;

  top: calc(var(--form-field-height) - var(--form-field-border-width));
  left: 0;
  z-index: 1;

  overflow-y: auto;
  margin-top: var(--form-field-border-width);
  max-height: 30vh;
  width: 100%;
  white-space: nowrap;
  min-width: 100%;
  border-bottom: 1px solid var(--oyster-0);
  border-left: 1px solid var(--oyster-0);
  background: var(--white);
  box-shadow: 0 0 8px 1px rgba(0, 0, 0, 0.15);
}

.menu:empty {
  border: none;
  box-shadow: none;
}

.menu:focus {
  outline: none;
}

.item {
  padding: var(--margin-xs) var(--margin-sm);
  padding-top: var(--margin-xs);
  padding-bottom: var(--margin-xs);
  cursor: pointer;
  white-space: nowrap;
}

.itemSelected {
  background-color: var(--carrot-3);
  color: white;
}
