.container {
  align-items: center;
  display: flex;
  flex-direction: column;
  flex: 1;
  gap: 15px;
  justify-content: center;
  max-width: 259.2px;
}

.quote {
  flex: 1;
  margin: 0;
  text-align: center;
}

.cnetItem {
  gap: 23px;
}