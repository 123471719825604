.container {
  position: relative;
  max-width: 300px;
}

.select {
  /** Remove the inheritance of text transform in Firefox. */
  text-transform: none;
  /** Remove the margin in Firefox and Safari */
  margin: 0;
  padding: 13px 25px 11px 0;
  width: 100%;
  height: 50px;
  transition: all 200ms ease-in-out;
  transition-property: border-color;
  background: white;
  border: none;

  appearance: none;
  color: inherit;
  text-align: center;
  font-weight: inherit;
}

.select:-webkit-autofill {
  box-shadow: inset 0 0 0 9999px white;
}

/** Hide IE 11 select arrow */
.select::-ms-expand {
  display: none;
}

.arrow {
  position: absolute;
  pointer-events: none;
  top: 10px;
  right: 7px;
}

@media (--viewport-lg) {
  .select {
    padding-left: 1rem;
  }
}
