.background {
  background-color: var(--chickpea-1);
  position: absolute;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
}

.container {
  position: relative;
  overflow: hidden;
  height: 100%;
  width: 100%;
}

.headline {
  margin-top: var(--margin-lg);
  display: block;
  text-align: center;
}

.content {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  text-align: center;

  position: absolute;
  left: 50%;
  top: 50%;
  transform: translate(-50%, -50%);
  width: 315px;
}

.icon {
  margin-bottom: var(--margin-sm);
}

.food {
  width: 58px; 
  height: 36px;
}

.circle {
  width: 36px;
  height: 36px;
}

.arrowRight {
  position: absolute;
  right: 20px;
  top: 45px;
  width: 49px;
  height: 25px; 
}

.arrowDown {
  position: absolute;
  right: 55px;
  bottom: -60px;
  width: 28px;
  height: 56px; 
}

.arrowUp {
  position: absolute;
  left: 45px;
  top: 30px;
  width: 49px;
  height: 37px; 
}

.arrowLeft {
  position: absolute;
  left: 40px;
  top: 2px;
  width: 56px;
  height: 28px; 
}

.arrowUp, .arrowDown {
  display: block;
}

.arrowLeft, .arrowRight {
  display: none;
}

.kibble,
.turkey {
  height: 350px;
  position: absolute;
  transition: opacity 300ms ease-in-out;
}

.inactive {
  opacity: 0.7;
}

.kibble {
  top: 0px;
  left: -220px;
}

.turkey {
  bottom: -191px;
  right: -214px;
}

@media (--viewport-sm) {
  .kibble {
    top: 20px;
    left: -200px;
  }
  
  .turkey {
    bottom: -145px;
    right: -188px;
  }
}

@media (--viewport-lg) {
  .arrowLeft, .arrowRight {
    display: block;
  }

  .arrowUp, .arrowDown {
    display: none;
  }

  .kibble,
  .turkey {
    height: 577px;
    top: 50%;
    transform: translateY(-50%);
  }

  .kibble {
    left: unset;
    right: 70%;
  }

  .turkey {
    left: 69%;
  }

  .theirs {
    top: 43%;
    left: 40%;
  }

  .ours {
    top: 52%;
    left: 55%;
  }

  .headline {
    margin-top: var(--margin-3xl);
  }
}
