.image {
  width: 100%;
  vertical-align: bottom;
}

.card {
  background-color: var(--chickpea-0);
  text-align: center;
  padding: var(--margin-sm) var(--margin-md);
}

.section {
  position: relative;
}

.section:not(:last-child) {
  margin-bottom: var(--margin-lg);
}

.quoteBlock {
  display: none;
}

@media (--viewport-lg) {
  .section {
    display: flex;
  }

  .section:not(:last-child) {
    margin-bottom: var(--margin-xxl);
  }

  .inverted {
    flex-direction: row-reverse;
  }

  .quoteBlock {
    display: flex;
    flex-direction: column;
    justify-content: center;
    padding: 0 0 0 var(--margin-3xl);
  }

  .inverted .quoteBlock {
    padding: 0 var(--margin-3xl) 0 0;
  }

  .imageAndText,
  .quoteBlock {
    width: 50%;
  }

  .card {
    padding: var(--margin-md) var(--margin-xl);
  }
}
