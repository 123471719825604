:root {
  --treats-max-width: 28.75rem;
}

.wave {
  width: 100%;
}

.heroImageContainer {
  display: flex;
  flex-direction: column-reverse;
  max-width: 37.5rem;

  picture {
    width: 100%;
  }
}

.section {
  align-items: center;
  flex: 1;
  width: 100%;

  &:not(.heroImageContainer) {
    padding: 0 var(--margin-md);
  }
}

.image {
  height: 147px;
  object-fit: cover;
  width: 100%;
  vertical-align: bottom;
}

.limitedOffer {
  background-color: var(--kale-2);
  padding: 12px var(--margin-md);
  text-align: center;
  width: 100%;
  line-height: 0;
}

@media (--viewport-md) {
  .hero {
    padding: 0 var(--margin-2xl);
  }

  .limitedOffer {
    padding: 19px var(--margin-md);
  }

  .section {
    &:not(.heroImageContainer) {
      max-width: 461px;
      padding: 0;
    }
  }

  .heroImageContainer {
    margin-top: var(--margin-sm);
    padding: 0;
    flex-direction: column;

    picture {
      width: 100%;
    }
  }

  .image {
    height: 340px;
  }

  .multiTreatHeight {
    height: 522px;
  }
}
