@use '@farmersdog/corgi-x/src/styles/constants-legacy.scss' as *;

.blockquote {
  margin: 0;
  padding: 0;
  display: flex;
}

.divider {
  margin: 0;
  margin-right: $margin-md;
}

.quote {
  margin: 0;
}

.cite {
  display: block;
  margin: 0;
  margin-top: $margin-sm;
  padding: 0;
  font-style: normal;
}
