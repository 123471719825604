@use '@farmersdog/corgi-x/src/styles/constants-legacy.scss' as *;

.title {
  margin: 0;
}

.content {
  text-align: left;
  margin-top: $margin-md;
}

.cta {
  margin-top: $margin-lg;
  text-align: center;
}
