.wave {
  width: 100vw;
  position: absolute;
  left: 0;
}

.footer {
  margin-top: 0px !important;
  min-height: 600px;
}

@media (--viewport-md) {
  .footer {
    min-height: 450px;
  }
}
