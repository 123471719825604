.imageContainer {
    display: flex;
    justify-content: center;
    margin-top: var(--margin-xl);
    margin-bottom: var(--margin-xl);
}

.image {
    height: 160px;
}

@media (--viewport-md) {
    .image {
        height: 220px;
    }
}
