.container {
  text-align: center;
  position: relative;
  min-height: 600px;
}

.withTopOverlay::after,
.withRightOverlay::after {
  top: 0;
  left: 0;
  content: '';
  width: 100%;
  z-index: -1;
  height: 100%;
  display: block;
  position: absolute;
}

.withTopOverlay::after {
  background: linear-gradient(
    180deg,
    rgba(0, 0, 0, 0.2) 9.5%,
    rgba(0, 0, 0, 0) 64.5%
  );
}

.cta {
  height: 100%;
  align-self: flex-start;
  padding: var(--margin-md);
}

.ctaTextOnBottom {
  order: 1;
  padding-top: 0;
  align-self: flex-end;
  padding-bottom: var(--margin-md);
}

@media (--viewport-lg) {
  .ctaTextOnBottom {
    order: 0;
  }

  .desktopBreakline {
    display: block;
  }

  .container {
    text-align: left;
    position: relative;
    height: 600px;
  }

  .cta {
    padding-top: var(--margin-sm);
    padding-left: var(--margin-4xl);
    padding-bottom: 52px;
  }

  .withRightOverlay::after {
    background: linear-gradient(
      90.28deg,
      rgba(112, 71, 42, 0.2) 38.32%,
      rgba(96, 74, 58, 0) 52.22%
    );
  }
}
