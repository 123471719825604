@use '@farmersdog/corgi-x/src/styles/constants-legacy.scss' as *;

.lightbox {
  margin: auto;
  display: flex;
  overflow: hidden;
}

.content {
  overflow: hidden;
  text-align: center;
  display: flex;
  flex-direction: column;
  width: 100%;
  margin-bottom: $margin-xxl;
}

.content:last-child {
  // Form component override
  margin-bottom: unset;
}

.slider {
  flex: 1;
}

.heading {
  display: flex;
  align-items: center;
}

.title {
  // offset close button
  margin-left: 96px;
  flex: 1;
}

.bowlGraphic {
  margin: 0;
  padding: 0;
  width: 100%;
  height: 120px;
  position: absolute;
  left: 0;
  top: calc(100% - 116px);
  z-index: -1;
}

.leftBowl {
  position: absolute;
  bottom: -116px;
  left: -124px;

  width: 240px;
}

.rightBowl {
  position: absolute;
  right: -124px;
  bottom: -116px;

  width: 240px;
}

.buttons {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
}
