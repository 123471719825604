.RecipesStack {
  overflow-x: hidden;
  overflow-y: hidden;
  position: relative;
  z-index: 1;
  white-space: nowrap;
  text-align: center;
}

.RecipesStack span {
  width: 50%;
  display: inline-block;
}

/* additional properties applied when two recipes are displayed */
.RecipesStack[data-packs='2'] > span:nth-child(1) {
  transform: translateX(25%) scale(0.9);
}
.RecipesStack[data-packs='2'] > span:nth-child(2) {
  transform: translateX(-25%);
}

/* additional properties applied when three recipes are displayed */

.RecipesStack[data-packs='3'] > span:nth-child(1) {
  transform: translateX(0%) scale(0.9);
}
.RecipesStack[data-packs='3'] > span:nth-child(2) {
  transform: translateX(0) scale(0.9);
}
.RecipesStack[data-packs='3'] > span:nth-child(3) {
  transform: translateX(-150%);
}
