.Sticky {
  position: fixed;

  &-entered {
    @supports (position: sticky) {
      position: sticky;
    }
  }

  &-exiting {
    @supports (position: sticky) {
      position: sticky;
    }
  }

  &-no-polyfill {
    position: relative;
  }
}
