.Slider {
  overflow: hidden;
}

.Slider-slides {
  display: flex;
}

.Slider-slide {
  flex-grow: 1;
  flex-basis: 0;
  min-width: 200px;
  transition: visibility 0.25s ease-in-out;
}

.Slider.animate .Slider-slides {
  transition: transform 0.25s ease-in-out;
}

.Slider.animate .Slider-slide {
  transition: visibility 0.25s ease-in-out;
}
