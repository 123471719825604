@use '@farmersdog/corgi-x/src/styles/constants-legacy.scss' as *;
@use 'styles/accessibility.scss' as *;

.stepContainer {
  display: flex;
  flex-direction: column;
  max-width: 400px;
}

.stepTitle {
  margin: 0;
}

.stepDescription {
  margin: $margin-sm 0 0 0;
  @media (min-width: 375px) and (max-width: 575px){
    font-size: 12px;
    line-height:20px;
  }
}

@media (max-width: $lg) {
  .stepTitle {
    font-size: 21px;
  }
}

@media (max-width: 575px) {
  .stepContainer {
    width: 180px;
  }
  .stepDescription {
    font-size: 12px;
  }
}
