@use 'styles/mixins.scss' as *;
@use 'normalize.scss';
@use 'constants/fonts.scss' as *;

@use '@farmersdog/corgi-x/src/styles/constants-legacy.scss' as *;

html {
  background-color: $white;
}

*,
*:before,
*:after {
  box-sizing: border-box;
}

// Keep focus outlines consistent between platforms
*:focus {
  @include outline();
}

html,
body {
  margin: 0;
  padding: 0;
  font-size: 16px;
  font-family: $article;
}

body {
  color: $charcoal-3;
}

body > img[width='1'][height='1'] {
  visibility: hidden;
  position: absolute;
  bottom: -1px;
}

h1,
h2,
h3 {
  margin: 0;
  font-weight: normal;
}

a {
  color: $carrot-2;
  text-decoration: none;
}

[hidden] {
  display: none !important;
}

hr {
  height: 1px;
  border: 0;
  background-color: $oyster-0;
}

label {
  display: inline-block;
}

small {
  display: inline-block;
  line-height: 1.4em;
}

b,
strong {
  font-weight: bold;
}

input {
  -webkit-appearance: none;
  -webkit-border-radius: 0;
}

/* Overrides for Corgi variables. */
:root {
  /*
    AppNav z-index is hard-coded to a high number.
    This is a temporary fix for corgi-x modal to properly overlay on top of it.
    https://app.shortcut.com/farmersdog/story/96962
  */
  --tfd-lightbox-z-index: 9002;
  --tfd-appnav-z-index: 9004;
  --tfd-banner-min-height: 45px;
}

/* 
Temporary fix to keep Gladly chat window positioned to the right.
https://app.shortcut.com/farmersdog/story/120144/
 */
#gladlyChat_container {
  right: 0px !important;
}
