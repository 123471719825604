.line {
    display: block;
    width: 100%;
    position: relative;
    margin: var(--margin-sm) 0;
  }
  
  .line::before {
    z-index: 0;
    position: absolute;
    top: 50%;
  
    display: block;
    width: 100%;
    height: 2px;
    background: var(--charcoal-1);
    content: '';
    transform: translateY(-50%);
  }
  
  .first::before {
    width: 50%;
    left: 50%;
    transform: translate(1%, -50%);
  }
  
  .last::before {
    width: 50%;
    left: 50%;
    transform: translate(-101%, -50%);
  }
  
  .line > * {
    position: relative;
    margin: 0 auto;
  }
  