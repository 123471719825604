.container {
  box-sizing: border-box;
  width: 100%;
  position: relative;
  overflow: hidden;
  max-width: 100vw;
}

.horizontalSnap {
  box-sizing: border-box;
  list-style-type: none;
  padding: 0;
  margin: 0;
  flex-wrap: nowrap;
}

.message,
.dots {
  text-align: center;
  margin: 0 auto;
}

.arrow-buttons-container {
  align-items: center;
  bottom: 0;
  display: flex;
  justify-content: space-between;
  left: 50%;
  position: absolute;
  top: 0;
  transform: translateX(-50%);
  width: 62.5%;
  z-index: 1;
}

.arrow-button {
  background: #FFFCF899;
  border: none;
  border-radius: 50%;
  padding: 7px;
  height: 72px;
  width: 72px;
  margin: 0;
  padding: 0;
}

.arrow-button:disabled {
  opacity: 0.25;
}

.backwards-button{
  left: 0;
}

.forward-button {
  right: 0;
}

.is-not-wide .message {
  margin-top: var(--margin-sm);
}

.is-not-wide .dots {
  margin-top: var(--margin-md);
}

.is-wide .message {
  margin-top: var(--margin-md);
}

.is-wide .dots {
  margin-top: var(--margin-md);
}

.disable-user-scroll {
  overflow: hidden;
}
