@use '@farmersdog/corgi-x/src/styles/constants-legacy.scss' as *;

.container {
  background: $white;
  padding: 72px 30px;
  text-align: center;

  p {
    display: block;
    max-width: 588px;
    margin: 17px auto 0;
  }

  a {
    margin-top: $margin-md;
  }

  &.lg {
    padding: $margin-xxl 30px 96px;

    p {
      margin-top: 24px;
    }
  }
}
