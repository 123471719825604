@use '@farmersdog/corgi-x/src/styles/constants-legacy.scss' as *;

.fresh-instead {
  padding: $margin_md 0;
  background: $kale-3;
}

.container {
  display: flex;
  align-items: center;
  justify-content: space-between;
}

.xs .container {
  flex-direction: column;
}

.container > * {
  grid-column: 1 -1;
}

.xs .fresh-instead-cta {
  margin-top: $margin_md;
}

.heading {
  grid-column: 1 / span 3;
}

.copy {
  grid-column: 7 / 13;
  margin: 0;
}

.xs .heading,
.xs .copy {
  grid-column: 1 / -1;
  text-align: center;
}

.xs .heading {
  margin-bottom: $margin_md;
}
