.placeholderInput {
  width: 12rem;
  margin-top: 0 !important;
}

.breedsInput {
  margin-top: 0 !important;
}

.addBreedButtons {
  margin-top: 0;
  width: 100vw;
  position: absolute;
  left: 0;
}

@media (--viewport-md) {
  .addBreedButtons {
    margin-top: var(--margin-md);
  }
}

.wrapper {
  display: inline-block;
}
