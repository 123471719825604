@use '@farmersdog/corgi-x/src/styles/constants-legacy.scss' as *;

.jadaPuppy {
  margin: 24px auto 0;
}

.container {
  background: $white;
  padding: 72px 30px 96px;
  text-align: center;

  p {
    max-width: 375px;
    margin: 6px auto 0;
  }

  h2 {
    margin: $margin-md auto 0;
  }

  &.lg {
    padding: 96px 30px 120px;

    p {
      max-width: 375px;
      margin-top: $margin-sm;
    }
  }
}
