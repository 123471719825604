.marginBottom {
  margin-bottom: var(--margin-lg);
}

.weightFormHint {
  max-width: 315px;
  margin: var(--margin-lg) auto;
}

.input {
  max-width: 76px;
}

@media (--viewport-md) {
  .weightFormHint {
    max-width: unset;
  }
}

.targetWeightFormHint {
  margin: 0 auto var(--margin-md);
}

.frontLabel {
  display: inline;
}

.inputContainer {
  display: inline-block;
}
