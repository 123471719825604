@use "sass:color";
/* Outline alternative */
@mixin outline($color) {
  &:focus {
    outline: none;
    box-shadow: 0 0 0 3px rgba($color, 0.3);
  }
}

/* Reset the button style */
@mixin button-reset() {
  overflow: visible;
  box-sizing: content-box;
  padding: 0;
  border: 0;
  background: none;
  color: inherit;
  font: inherit;
  line-height: normal;

  appearance: none;
  &::-moz-focus-inner,
  &::-moz-focus-inner {
    padding: 0;
    border: 0;
  }
}

/* Indent by $margin all lines in a text except the first line */
@mixin indent($margin) {
  padding-left: $margin;
  text-indent: -$margin;
}

/* Make an element unselectable */
@mixin make-unselectable() {
  -khtml-user-select: none; /* Konqueror */
  -webkit-touch-callout: none; /* iOS Safari */
  -webkit-user-select: none; /* Chrome/Safari/Opera */
  -moz-user-select: none; /* Firefox */
  -ms-user-select: none; /* Internet Explorer/Edge */
  user-select: none; /* Non-prefixed version, currently not supported by any browser */
}

/* Create a background parallax effect */
@mixin add-parallax($height: 100vh) {
  height: $height;
  background-attachment: fixed;
  background-position: center;
  background-size: cover;
  background-repeat: no-repeat;
}

@mixin add-shadow() {
  box-shadow: 0 15px 18px rgba(236, 236, 236, 0.44);
}

@function shade($color, $percent) {
  @return color.mix(#000, $color, $percent);
}
