@media (--viewport-xs) {
  .title {
    margin-top: var(--tfd-spacing-md);
    margin-right: 0px;
    margin-bottom: var(--tfd-spacing-xl);
    margin-left: var(--tfd-spacing-md);
    text-align: left;
  }
  .subtitle {
    display: block;
  }
}

@media (--viewport-md) {
  .title {
    margin-top: var(--tfd-spacing-xl);
    margin-right: 0px;
    margin-bottom: var(--tfd-spacing-xl);
    margin-left: 0px;
    text-align: center;
  }
  .subtitle {
    display: inline;
  }
}
