.container {
  display: flex;
  align-items: center;
  margin-left: auto;
  margin-right: auto;
  z-index: 4;
  flex: 0 0 auto;
}

.arrow {
  margin-right: 9px;
}
