.stickyContainer {
  position: fixed;
  bottom: 0;
  left: 0;
  background-color: white;
  width: 100vw;
}

.stickyContainerFront {
  z-index: 2;
}

.stickyContainerBack {
  z-index: -2;
}

.ctaContainer {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  row-gap: var(--margin-sm);
  width: 100vw;
  padding: var(--margin-sm) 0 var(--margin-md);
  box-shadow: 0 0px 10px 1px rgba(0, 0, 0, 0.25);
}

.priceText {
  margin-bottom: var(--margin-sm);
}

.pricePerDay {
  text-decoration-thickness: 1px;
}

.ctaButton {
  width: 340px;
}