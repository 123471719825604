.container { 
  background-color: #f0f3f0;
  border-top-left-radius: 20px;
  border-top-right-radius: 20px;
  height: fill-available;
  overflow: hidden;
}

.content { 
  padding: 0 var(--margin-md) var(--margin-md) var(--margin-md);
}

.imageContainer {
  position: relative;
}

.pill {
  position: absolute;
  bottom: 12px;
  left: 19px;
  padding: 4px 14px;
  border-radius: 20px;
  background: rgba(57, 56, 60, 0.6);
}

.list {
  list-style: none;
  margin-top: var(--margin-md);
  margin-bottom: var(--margin-sm);
  padding: 0;
}

.list li {
  display: flex;
  gap: var(--margin-sm);
  align-items: flex-start;
}

.list svg {
  width: 12.6px;
  flex-shrink: 0;
}

.quoteTitle {
  color: black;
  font-size: 8px;
  font-weight: 500;
  letter-spacing: 2px;
  margin-bottom: 8px;
  text-transform: uppercase;
}

.blockquote {
  margin: 0;
  background: white;
  padding: var(--margin-sm) 18px;
  margin-bottom: var(--margin-md);
}

.ctaContainer {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  width: 100%;
}
