@use '@farmersdog/corgi-x/src/styles/constants-legacy.scss' as *;

.heading {
    text-align: center;
    margin-bottom: $margin_lg;
    grid-column: 1/-1;
    grid-row: 1;
}

.xs .heading {
    text-align: left;
    margin-bottom: $margin_md;
}

.content {
    grid-column: 3/11;
    grid-row: 2;
}

.xs .content {
    grid-column: 1/-1;
}

.card {
    width: 100%;
    display: flex;
}

.xs .card {
    flex-direction: column;
}

.card > div {
    width: 50%;
}

.xs .card > div {
    width: 100%;
}


.image {
    width: 100%;
    height: 100%;
    border-radius: $margin_md 0 0 $margin_md;
    object-fit: cover;
}

.xs .image {
    border-radius: $margin_md $margin_md 0 0;
    height: 250px;
}

.fresh-plan {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
}

.xs .fresh-plan {
    margin-top: $margin_md;
    padding-bottom: $margin_lg;
}

.fresh-plan ul {
    list-style-type: none;
    padding-left: 0;
    margin-bottom: $margin_lg;
}

.fresh-plan li {
    display: flex;
    align-items: center;
    margin-bottom: $margin_xs;
}

.fresh-plan li svg {
    margin-right: $margin_sm;
}
