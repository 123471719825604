@use '@farmersdog/corgi-x/src/styles/constants-legacy.scss' as *;

.figure {
  margin: 0;
  padding: 0;
}

.container {
  text-align: center;
  margin: 0;
}

.meat {
  display: block;
  margin: none;
}

.meat + .meat {
  margin-top: $margin-lg;
}

.bull {
  display: block;
  margin: $margin-lg 0 0 0;
}
