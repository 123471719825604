.DiscountBadge {
  margin-bottom: 8px;
  margin-left: 5px;
}

.DiscountBadgeTextContainer {
  margin-left: 10px;
  margin-top: 4px;
}

.DiscountBadgeText {
  margin-bottom: 0px;
  margin-top: 8px;
  text-transform: uppercase;
  white-space: nowrap;
}

.mobileFreeTrialText {
  font-size: 10px;
}

.DiscountBadgeMask {
  background-color: var(--kale-3);
}

.DiscountBadgeSvgContainer {
  align-items: center;
  display: flex;
  justify-content: center;
}

@media (--viewport-lg) {
  .DiscountBadge {
    margin-bottom: 0;
    margin-left: 0;
    margin-top: -9px;
  }

  .DiscountBadgeText {
    margin-top: 0;
    text-transform: none;
  }
}
