@use '@farmersdog/corgi-x/src/styles/constants-legacy.scss' as *;

.image {
  width: 100vw;
}

.lg.image {
  width: 100%;
  position: sticky;
  top: $margin-4xl;
}
