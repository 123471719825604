.arrow {
  fill: var(--charcoal-3);
  transform-origin: center;
  transform: rotate(-90deg);
  width: 12px;
  height: 12px;
}

.arrowOpen {
  transform: rotate(90deg);
}
