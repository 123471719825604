@use '@farmersdog/corgi-x/src/styles/constants-legacy.scss' as *;

.card {
  background-color: $oyster-0;
  margin: $margin-md 0;
  padding: $margin-md;
}

.petInfo {
  display: flex;
  align-items: center;
}

.petName {
  margin-left: $margin-sm;
}

.card blockquote {
  margin: $margin-md 0;
}
