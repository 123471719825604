@use '@farmersdog/corgi-x/src/styles/constants-legacy.scss' as *;

.xs.container {
  margin-top: $margin-xl;
}

.lg.container {
  display: flex;
  margin-top: $margin-3xl;
}

.xs .content {
  margin-top: $margin-md;
}

.lg .content {
  margin-top: 0;
}
