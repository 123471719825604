.innerContainer {
  display: flex;
  position: fixed;
  left: 0;
  bottom: 0;
  width: 100vw;
  background-color: var(--white);
  box-shadow: 0px 0px 5px rgba(0, 0, 0, 0.25);
  z-index: 4;
  height: 120px;
  padding-top: var(--tfd-spacing-sm);
}

.textContainer {
  height: 26px;
}

.button {
  display: block;
  max-width: 327px;
  flex: 1;
  margin-left: var(--tfd-spacing-sm);
  margin-right: var(--tfd-spacing-sm);
  margin-bottom: var(--tfd-spacing-md);
  margin-top: var(--tfd-spacing-sm);
}

.pricePerDay {
  text-decoration-thickness: 1px;
}

@media (--viewport-md) {
  .innerContainer {
    height: 120px;
  }

  .button {
    position: relative;
  }
}
