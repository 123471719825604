.backgroundImage,
.moveBackgroundLower {
  top: 0;
  left: 0;
  z-index: -1;
  height: 100%;
  position: absolute;
  @supports (object-fit: cover) and (object-position: 0% 50%) {
    width: 100%;
    object-fit: cover;
  }
}

.backgroundImage {
  @supports (object-fit: cover) and (object-position: 0% 50%) {
    object-position: 75% 50%;
  }
}

.moveBackgroundLower {
  @supports (object-fit: cover) and (object-position: 0% 50%) {
    object-position: 75% 0%;
  }
}

.q5BackgroundImage {
  @supports (object-fit: cover) and (object-position: 0% 50%) {
    object-position: 78% 40%;
  }
}
