@use '@farmersdog/corgi-x/src/styles/constants-legacy.scss' as *;

.dogWithBone {
  max-width: 350px;
  margin-top: $margin-sm;
}

.container {
  text-align: center;
}
