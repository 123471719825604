.container {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;

  padding: 48px 10px 24px 10px;
}

.title {
  font-style: italic;
}

.link + .link {
  margin-left: 50px;
}

.accessibilityThemeExtraPadding {
  margin-bottom: var(--margin-lg);
}

@media (--viewport-sm) {
  .container {
    padding: 48px 30px 24px 30px;
  }
}

@media (--viewport-lg) {
  .container {
    flex-direction: row;
    padding: 76px 30px 46px 48px;
  }
}
