.bowlPackSectionWrapper {
  width: calc(100% - 2 * var(--tfd-spacing-md)) !important;
  text-align: center;
  position: relative;
}

.bowlPackSectionParagraph {
  font-weight: 500;
}

.bowlImageContainer {
  width: calc(100% + 2 * var(--tfd-spacing-md));
  margin-left: calc(-1 * var(--tfd-spacing-md));
  margin-right: calc(-1 * var(--tfd-spacing-md));
  margin-bottom: 11rem;
  position: relative;
}

.bowlImageContainer picture {
  margin: auto;
}

.packImageContainer {
  width: 100%;
  position: absolute;
  top: calc(23rem - 20vw);
  right: -24px;
}

.packImageContainer picture {
  margin-left: auto;
}

.bowlImage,
.packImage {
  width: 100%;
  object-fit: scale-down;
}

.arrowTop {
  position: absolute;
  left: 24px;
  top: -36px;
}

.arrowBottom {
  position: absolute;
  bottom: -12rem;
  right: 48px;
}
