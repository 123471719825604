.container {
  position: absolute;
  left: 0;
  right: 0;
  margin-left: auto;
  margin-right: auto;
  width: 100%;
  max-width: 800px;
  top: 50%;
  transform: translateY(-50%);
  z-index: var(--tfd-appnav-z-index);
}

.links {
  display: flex;
  align-items: center;
  justify-content: center;
  height: 79px;
}

.links > span {
  flex: 1 1 0px;
  text-align: center;
}

.links a.completed {
  color: var(--kale-3);
  opacity: 1;
}

.links a.disabled {
  color: var(--charcoal-1);
  opacity: 1;
}

.links a.active {
  color: var(--carrot-2);
}

.progressAlignment {
  padding: 0 10% 0 11%;
}
