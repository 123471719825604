@use '@farmersdog/corgi-x/src/styles/constants-legacy.scss' as *;

.loading,
.departmentHeader {
  margin-top: $margin-lg;
}

.list {
  margin: 0;
  padding: 0;
  list-style-type: none;
}

.department {
  margin: 0;
  padding: 0;
}

.job {
  margin: $margin-md 0;
  padding: 0;
}
