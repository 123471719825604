@use '@farmersdog/corgi-x/src/styles/constants-legacy.scss' as *;

.container {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  flex: 1;
  max-width: 240px;
  margin: 1rem;
}

.quote {
  margin: $margin-sm 0 0;
  flex: 1;
  text-align: center;
}
