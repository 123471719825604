@use '@farmersdog/corgi-x/src/styles/constants-legacy.scss' as *;

.root {
  visibility: hidden;
  opacity: 0;
  transform: translateY(12px);
  position: fixed;
  padding: 12px 10px;
  right: 6px;
  bottom: 24px;
  background-color: #385a9d;
  border: 2px solid #ffffff;
  width: 50px;
  height: 50px;
  border-radius: 50%;
  transition: visibility 0s, opacity 0.2s linear, transform 0.2s linear;
  cursor: pointer;
}

.root:hover {
  background-color: #4167b4;
}
.root:active {
  background-color: #314d87;
}

.root:global(.gladly-show).isVisible {
  visibility: visible;
  opacity: 1;
  transform: translateY(0);
}

.root svg {
  width: 24px;
  height: 24px;
}

.icon-container {
  position: relative;
}

.unread {
  display: none;
  position: absolute;
  top: -4px;
  right: -2px;
  width: 10px;
  height: 10px;
  border: 1px solid #ffffff;
  border-radius: 10px;
  background-color: $carrot-2;
}

.root:global(.gladly-unread) .unread {
  display: block;
}
