@use '@farmersdog/corgi-x/src/styles/constants-legacy.scss' as *;

.mainContainer {
  display: flex;
}

.animationContainer {
  display: block;
  height: 495px;
  min-width: 103px;
  max-width: 103px;
}

.animation {
  height: 100%;
  width: 100%;
}

.stepsContainer {
  display: flex;
  flex-direction: column;

  & > div:nth-child(1) {
    margin-top: 40px;
  }
  & > div:nth-child(2) {
    margin-top: 45px;
  }
  & > div:nth-child(3) {
    margin-top: 75px;
  }

  @media (min-width: 375px){
    & > div:nth-child(2) {
      margin-top: 45px;
    }
  }
  @media (min-width: 575px){
    & > div:nth-child(2) {
      margin-top: 70px;
    }
    & > div:nth-child(3) {
      margin-top: 100px;
    }
  }
}

@media (min-width: $md) {
  .mainContainer {
    flex-direction: column;
  }

  .animationContainer {
    margin: auto;
    height: auto;
    max-width: 1200px;
    margin-top: $margin-sm;
    padding: 0 50px;
  }

  .stepsContainer {
    flex-direction: row;
    text-align: center;
    justify-content: space-around;
    margin-top: $margin-sm;
    column-gap: 13%;

    & > div:nth-child(1),
    div:nth-child(2),
    div:nth-child(3) {
      margin-top: 0;
    }
  }
}

@media (min-width: $lg) {
  .animationContainer {
    padding: 0 100px;
    margin-top: 0;
  }

  .stepsContainer{
    column-gap: 8%;
  }
}
