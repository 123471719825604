.container {
  position: relative;
}

.logoContainer {
  display: flex;
  align-items: center;
  /* Width is based on screen width - 1/2 the size of nav links */
  width: calc(50% - 400px);
}

.logo {
  margin-right: var(--margin-md);
}
