.container {
  position: fixed;
  background: var(--white);
  bottom: 0;
  display: flex;
  gap: var(--margin-sm);
  padding: var(--margin-md);
  margin-top: var(--margin-lg);
  width: 100%;
}

.button {
  flex: 1;

  &.secondary {
    background: transparent;
  }
}

@media (--viewport-md) {
  .container {
    background-color: transparent;
    padding: 0;
    position: relative;
  }
}

