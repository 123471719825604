@import './spring-down-in.css';
@import './spring-down-out.css';
@import './fade-in.css';
@import './fade-out.css';

.entering {
  animation-timing-function: linear, linear;
  animation-name: spring-down-in, fade-in;
  animation-duration: 600ms, 150ms;
  animation-fill-mode: forwards;
}

.exiting {
  animation-timing-function: linear;
  animation-name: spring-down-out, fade-out;
  animation-duration: 600ms, 150ms;
  animation-fill-mode: forwards;
}
