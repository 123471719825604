.container {
  display: flex;
  flex-direction: column;
  align-items: center;
  margin-bottom: var(--tfd-spacing-xl);
  overflow: hidden;
}

.content {
  display: flex;
  flex-direction: column;
  max-width: 620px;
  margin: 0 auto;
  margin-bottom: var(--tfd-spacing-xl);
  justify-content: center;
  text-align: center;
}

.icon {
  margin-bottom: var(--tfd-spacing-sm);
}

.title {
  margin-bottom: var(--tfd-spacing-md);
}

.quote {
  margin: 0;
  margin-bottom: var(--tfd-spacing-md);
}

.picture {
  display: flex;
  justify-content: center;
  flex: 1;
  width: 315px;
}

.picture img {
  max-width: 315px;
}

@media (--viewport-lg) {
  .container {
    margin-bottom: var(--tfd-spacing-xxl);
  }

  .picture {
    width: auto;
    min-width: 50%;
    margin-right: var(--tfd-spacing-md);
  }

  .picture img {
    max-width: 375px;
    background-color: aqua;
  }
}
