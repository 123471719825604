@use 'styles/mixins.scss' as *;

.Signup {
  display: flex;
  min-height: 100vh;
  flex-direction: column;
  -webkit-font-smoothing: subpixel-antialiased;

  .Signup-Content {
    flex: 1;
  }

  .Footer {
    margin-top: auto;
  }
}

.Signup-fadeEl {
  position: relative;
}

.Signup-fadeEl > div {
  position: absolute;
}

.Signup-fadeEl.Signup-fadeEl-fresh > div {
  top: 300px;
}

.Signup-fadeEl.Signup-fadeEl-diy > div {
  top: 900px;
}

.Signup-loading {
  padding-top: 100px;
  min-height: 100vh;
  text-align: center;
}

// Me page
.SignupMe {
  min-height: calc(100vh - 250px);
}

.SignupSuccess {
  .Frequency {
    @include small-screens() {
      margin: 20px -29px;
      box-shadow: none;
    }
  }
}
