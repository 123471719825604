:has(.backdrop) {
  z-index: calc(var(--tfd-appnav-z-index) + 1) !important;
}

.ExitIntentModal {
  height: 655px;
  overflow: hidden;
  padding: var(--tfd-spacing-lg);
  text-align: center;
}

.ExitIntentModal h1 {
  margin-bottom: var(--tfd-spacing-sm);
  margin-top: var(--tfd-spacing-sm);
}

.content {
  padding: 0 var(--tfd-spacing-lg);
  position: relative;
  text-align: center;
}

.top {
  width: 456px;
}

.optionsForm {
  text-align: left;
}

.accident {
  height: 231px;
  width: 100%;
}

.freeResponseInput,
.freeResponseSubmit {
  height: 50px;
}

.freeResponseInput {
  margin: 0 var(--tfd-spacing-xs) 0 29px;
  padding: 0;
  width: 300px;
}
