@use '@farmersdog/corgi-x/src/styles/constants-legacy.scss' as *;

.HeaderProgress {
  position: relative;
  display: inline-block;
  vertical-align: top;

  width: 100%;
  height: 6px;
  transform: translateY(-3px);

  &.shiftUp {
    transform: translateY(-9px);
  }

  &-bar {
    position: absolute;
    top: 2px;

    width: 100%;
    height: 2px;
    transition: width $animation-standard;

    &.active {
      width: 0%;
      background: $kale-3;
    }

    &.inactive {
      width: 100%;
      background: $oyster-0;
    }
  }

  &-steps {
    position: absolute;

    display: inline-flex;
    align-items: center;
    justify-content: space-between;
    width: 100%;
    height: 100%;

    span {
      width: 10px;
      height: 10px;
      border: 2px solid $oyster-0;
      border-radius: 100%;
      background: $white;
      transition: border 0s ease;

      &.active {
        border-color: $kale-3;
      }

      &.inactive {
        border-color: $oyster-0;
        transition: border $animation-standard;
      }
    }
  }
}
