@use '@farmersdog/corgi-x/src/styles/constants-legacy.scss' as *;
@use 'styles/accessibility.scss' as *;

.title {
  margin: 0 0 $margin-sm 0;
}

del.strikeout {
  text-decoration: none;
}

.strikeout {
  display: inline-block;
  position: relative;
  vertical-align: bottom;
}

.strikeout::before {
  position: absolute;
  content: '';
  left: 0;
  top: calc(50% - 2px);
  right: 0;
  border-top: 3px solid;
  border-color: $carrot-2;
  transform: rotate(-8deg);
}

@media (min-width: $lg) {
  .container {
    max-width: none;
    width: 100%;
  }
}
