@use '@farmersdog/corgi-x/src/styles/constants-legacy.scss' as *;

.container, .copyright, .legalDetails {
  display: flex;
}

.container {
  flex-direction: column;
  align-items: center;
  text-align: center;
  gap: var(--tfd-spacing-md);
  border-top: 4px dotted $kale-3;
  padding-top: $margin-lg;
}

@media (min-width: $lg) {
  .container {
    padding-top: $margin-xl;
    align-items: flex-start;
  }
}

.lg.container {
  flex-direction: row;
}

.light {
  border-top: 4px dotted $white;
}

.copyright {
  flex: 1;
  flex-direction: column;
  align-items: center;
  text-align: center;
  gap: 0;
}

@media (min-width: $lg) {
  .copyright {
    flex-direction: row;
    text-align: left;
    gap: var(--tfd-spacing-md);
  }
}

.lg .copyright {
  margin-bottom: 0;
  align-self: flex-start;
  text-align: left;
}

.legalDetails {
  white-space: nowrap;
  flex-direction: column;
  justify-content: flex-start;
  margin: 0;
  padding-left: 0px;
  list-style-type: none;
  gap: var(--tfd-spacing-md);
}

.do-not-sell-my-info {
  flex-basis: 100%;
}

.lg .legalDetails {
  text-align: right;
  flex-direction: row;
}

.legalDetails .link {
  margin: 0 0 $margin-xs $margin-md;
}

.xs .legalDetails .link {
  margin-left: 0;
}

.longLiveDogs {
  text-align: left;
}

@media (min-width: $lg) {
  .longLiveDogs {
    text-align: center;
  }
}

.xs .longLiveDogs {
  display: inline-block;
  padding: 0;
  border: none;
}
