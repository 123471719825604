
.outerContainer {
  position: absolute;
  height: 100%;
  width: 100%;
  top: 0;
  right: 0;
}

.container {
  display: flex;
  align-items: center;
  position: relative;
  height: 100%;
  width: 100%;
  max-width: 100%;
}

.container > div {
  width: 100%;
}

.container.split > div + div {
  padding-left: var(--margin-xl);
}

.item {
  display: flex;
  align-items: center;
  margin-top: var(--margin-sm);
  margin-bottom: var(--margin-sm);
  margin-left: 100px;
}

.split .item {
  margin-top: calc(-1 * var(--margin-md));
  margin-bottom: calc(-1 * var(--margin-md));
  margin-left: 0px;
}

.split .item > span {
  margin: var(--margin-xl) 0;
}

.leftItem {
  margin-left: var(--margin-xl) !important;
}

.rightItem {
  margin-right: var(--margin-md);
}

@media (--viewport-md) {
  .rightItem {
    margin-right: var(--margin-xl);
  }
}
