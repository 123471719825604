@use '@farmersdog/corgi-x/src/styles/constants-legacy.scss' as *;

.container {
  position: relative;
  padding-bottom: 100%;
  width: 100%;
  margin: $margin-sm;
}

.mixed,
.ingredients,
.divider {
  position: absolute;
  top: 45%;
  left: 50%;
  width: 100%;
  transform: translate(-50%, -50%);
}

.mixed {
  border-right: 4px solid transparent;
}

.ingredients {
  border-right: 4px solid transparent;
  // adjust centering
  transform: translate(-50.5%, -49.875%) scale(0.996);
}

.divider {
  height: 100%;
  border-right: 4px solid white;
}
