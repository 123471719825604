.container {
  display: flex;
  flex-direction: row;
  width: 100%;
  max-width: 1185px;
  margin-bottom: 104px;
}

.vet {
  flex: 1;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
}

.icon {
  margin-bottom: 24px;
}

.name {
  margin-bottom: 6px;
}

.quote {
  margin: 0;
  flex: 1 0 auto;
  align-self: stretch;
}
