.testimonial {
  margin: 0;
  padding: 1.5rem;
  border-radius: var(--tfd-border-radius-sm) * 2;
  background: #ffff;
  box-shadow: 0 3px 5px rgba(0, 0, 0, 0.1);
  text-align: left;
}

.starIcon {
  width: var(--tfd-spacing-md);
  height: var(--tfd-spacing-md);
}

.starIcon polygon {
  fill: var(--carrot-2);
}

.starIcon:first-child {
  margin-left: -4px;
}

.testimonialText {
  display: inline-block;
  margin-top: var(--tfd-spacing-xxs) * 2;
  margin-bottom: 0;
  vertical-align: middle;
}

.starcheck {
  display: inline-block;
  width: 16px;
  height: 16px;
  vertical-align: bottom;
  transform: translate(6px, -6px);
  fill: var(--instagram);
}
