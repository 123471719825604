.loginLink {
  display: block;
}

.success {
  text-align: center;
  margin: 10px 0;
  margin-bottom: var(--margin-lg);
}

.error {
  text-align: left;
  margin-left: 12px;
  color: var(--error-red);
}

.captchaError {
  text-align: left;
  margin: 24px 0 0 0;
}

/* fixes hidden overflow cutting off field labels */
.slider {
  margin-top: -5px;
  padding-top: 5px;
}

.form {
  margin-bottom: var(--margin-lg);
}

.lastLine {
  margin-bottom: 0;
}
