.container {
  background: var(--chickpea-1);
  padding-top: var(--tfd-spacing-xxl);
  padding-bottom: var(--tfd-spacing-xl);
}

.video {
  margin-bottom: var(--tfd-spacing-lg);
  height: 512px;
}

.videoQuote {
  margin: 0;
  max-width: 620px;
}

.videoQuote blockquote {
  margin: 0;
}

.oysterBackground {
  background-color: var(--oyster-0);
  padding-top: var(--tfd-spacing-md);
}

.ctaPadding {
  padding: 0 var(--tfd-spacing-md) var(--tfd-spacing-md);
}

@media (--viewport-lg) {
  .container {
    padding-bottom: var(--tfd-spacing-xxl);
  }

  .video {
    margin-bottom: var(--tfd-spacing-xl);
    height: 540px;
  }

  .oysterBackground {
    padding-top: 0;
  }

  .ctaPadding {
    padding: var(--tfd-spacing-lg) var(--tfd-spacing-md) var(--tfd-spacing-md);
  }
}
