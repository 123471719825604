@use '@farmersdog/corgi-x/src/styles/constants-legacy.scss' as *;

.container {
  background-color: $kale-3;
  position: relative;
}

.xs.container {
  padding-bottom: 0 !important;
}

.content > * {
  grid-column: 3 / -3;
  text-align: center;
}

.content > h2 {
  margin-bottom: $margin-sm;
}

.content > p {
  margin-top: $margin-sm;
  margin-bottom: $margin-sm;
}

.xs .content > * {
  grid-column: 1 / -1;
}

.first-image,
.second-image {
  position: absolute;
  max-width: 375px;
  width: 20%;
}

.first-image {
  top: 0;
  right: 0;
}

.second-image {
  bottom: 0;
  left: 0;
}

.xs .first-image,
.xs .second-image {
  position: relative;
  width: 95%;
  max-width: 300px;
  display: block;
}

.xs .first-image {
  margin-bottom: $margin-xl;
  margin-right: $margin-md;
  float: right;
}

.xs .second-image {
  margin-top: $margin-xl;
  margin-left: $margin-md;
}
