.section {
  background-color: var(--chickpea-1);
  padding-bottom: 4rem;
  position: relative;
}

.section.accessibilityTheme {
  background-color: var(--oyster-0);
  padding: var(--margin-3xl) 0 var(--margin-sm);
}

.backgroundImage {
  bottom: 0;
  max-height: 80%;
  object-fit: cover;
  position: absolute;
  width: 100%;
}

.box {
  background: var(--white);
  border-radius: 8px;
  margin-bottom: var(--margin-xl);
  max-width: 600px;
  padding: var(--margin-xl) var(--margin-sm);
  position: relative;
  text-align: center;
  z-index: 1;
}

.boxTitle {
  max-width: 420px;
  margin: auto;
}

.box.accessibilityTheme {
  margin-top: var(--margin-xxl);
}

.button {
  margin: 1.5rem 0;
}

.accessiblilityThemeCta {
  background-color: var(--kale-2) !important;
  border-radius: 30px !important;
  width: 200px;
}

@media (--viewport-md) {
  .box {
    padding: var(--margin-xl) 0 var(--margin-lg);
    margin: 0 auto;
  }

  .boxTitle {
    max-width: auto;
  }
}

@media (--viewport-lg) {
  .section {
    padding-bottom: 0;
  }

  .recommendedSection {
    align-items: center;
    background-image: url(assets/paws-with-packs.jpg);
    background-position: center center;
    background-size: cover;
    display: flex;
    height: 920px;
  }

  .accessiblilityThemeCta {
    width: var(--cta-desktop-width);
  }
}
