.container {
  position: absolute;
  width: 100vw;
  left: 0;
  background-color: var(--oyster-0);
  color: var(--kale-3);
  margin-top: var(--margin-3xl);
}

.logoContainer {
  display: flex;
  flex-direction: column;
  justify-content: center;
}

.logo:first-child {
  margin-top: var(--margin-xxl);
}

.logo {
  max-height: 32px;
  margin-bottom: var(--margin-xxl);
}

@media (--viewport-xs) {
  .logoContainer {
    align-items: center;
    margin: var(--margin-xs) var(--margin-sm);
  }
}

@media (--viewport-lg) {
  .container {
    margin-top: var(--margin-xxl);
  }

  .logoContainer {
    flex-direction: row;
    justify-content: space-between;
    gap: var(--margin-lg);
    align-items: center;
    margin: var(--margin-xl) var(--margin-4xl);
    height: 32px;
  }

  .logo {
    margin: 0;
    height: 100%;
  }

  .logo:first-child {
    margin-top: 0;
    margin-left: var(--margin-lg);
  }

  .logo:last-child {
    margin-right: var(--margin-lg);
  }
}
