@use '@farmersdog/corgi-x/src/styles/constants-legacy.scss' as *;

.container {
  text-align: center;
}

.sm.container {
  text-align: left;
}

.md.container {
  text-align: left;
}

.lg.container {
  text-align: left;
}

.headline-list-container {
  display: grid;
  grid-template-columns: 1fr;
}

.sm .headline-list-container {
  display: grid;
  grid-template-columns: 1fr 1fr;
}

.md .headline-list-container {
  display: grid;
  grid-template-columns: 1fr 1fr 1fr 1fr;
}

.lg .headline-list-container {
  display: flex;
  flex-wrap: wrap;
  margin-left: calc(#{$margin-md} * -1);
}

.text {
  margin-bottom: 0;
}

.sm .text {
  margin-bottom: $margin-md;
}

.md .text {
  margin-bottom: $margin-md;
}

.lg .text {
  padding-left: $margin-md;
  padding-top: $margin-md;
  margin: 0;
}

.cta {
  margin-top: $margin-md;
}
