.wrapper {
  margin: auto;
  max-width: 1440px;
  width: 100%;
}

.container {
  width: 100%;
  text-align: center;
}

.slide {
  width: 68.5% !important;
}

.slide:first-child {
  margin-left: 15.75% !important;
}

.slide:last-child {
  margin-right: 15.75% !important;
}

.videoPlaceholder {
  display: block;
  object-fit: contain;
  width: 100%;
}

.slideTextContainer {
  width: calc(100% - 48px) !important;
  max-width: 590px;
  opacity: 0;
  animation: fadeIn 600ms ease-in-out forwards;
}

@keyframes fadeIn {
  from {
    opacity: 0;
  }
  to {
    opacity: 1;
  }
}

.buttonsContainer {
  gap: var(--tfd-spacing-sm);
  margin: var(--tfd-spacing-md) auto 0 auto;
  max-width: 58%;
  min-width: 219px;
}

.buttonsContainer .button {
  width: 100%;
}

@media (--viewport-md) {
  .slide {
    width: 55% !important;
  }

  .slide:first-child {
    margin-left: 22.5% !important;
  }

  .slide:last-child {
    margin-right: 22.5% !important;
  }
  
  .buttonsContainer {
    flex-direction: row;
    max-width: 590px;
  }
}
