@use '@farmersdog/corgi-x/src/styles/constants-legacy.scss' as *;

.container {
  position: relative;
}

.icon {
  margin-top: 0;
}

.headline {
  margin-top: 0;
  margin-bottom: $margin-xs;
}

.xs.container {
  width: 49%;
  text-align: center;
  margin: $margin-md auto;
}


.xs .headline {
  margin-top: $margin-sm;
}

.xs .text {
  margin-top: 12px;
}

.md.container {
  width: 49%;
  text-align: center;
  margin: $margin-md auto;
}

.md .headline {
  margin-top: $margin-md;
}


.lg.container {
  width: 100%;
  max-width: 223px;
  height: 200px;
  display: flex;
  gap: var(--margin-sm);
}

.lg .headline {
  width: 100%;
  white-space: nowrap;
}

.lg .text {
  margin: 0;
  max-width: 158px;
  text-align: left;
}
