@use '@farmersdog/corgi-x/src/styles/constants-legacy.scss' as *;

.title {
  margin: $margin-sm 0;
}

.description {
  margin: $margin-md 0;
}

.stats {
  margin: $margin-xxs 0;
}

.instructions {
  padding-left: 0;
  list-style: none;
}
