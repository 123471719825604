@use '@farmersdog/corgi-x/src/styles/constants-legacy.scss' as *;

.section {
  width: 100%;
  background-color: $chickpea-1;
  text-align: center;

  &.accessibilityTheme {
    background-color: var(--kale-0);
  }
}

.container {
  padding-top: 120px;
}

.imageContainer {
  max-width: 250px;
  height: $xs;
}

.image {
  position: relative;
  width: 100%;
  bottom: 270px;
}

.itemsSection {
  row-gap: $margin-md;
}

.itemsContainer {
  column-gap: $margin-xl;
  row-gap: $margin-xl;
  flex-wrap: wrap;
  & > * {
    width: 100%;
  }
}

@media (min-width: $sm) {
  .container {
    padding-top: 200px;
  }

  .imageContainer {
    max-width: 308px;
    height: $xs;
  }

  .image {
    bottom: 350px;
  }
}

@media (min-width: $md) {
  .itemsContainer {
    & > * {
      width: 45%;
    }
  }
}

@media (min-width: $lg) {
  .section {
    text-align: start;
  }

  .container {
    padding-top: $margin-3xl;
  }

  .imageContainer {
    max-width: 800px;
    max-height: 200px;
    height: auto;
  }

  .image {
    width: 100%;
    bottom: 260px;
  }

  .itemsSection {
    & > * {
      width: 93%;
    }
  }

  .itemsContainer {
    & > * {
      width: 100%;
    }
  }
}

.accessibilityThemeLink {
  color: var(--kale-3) !important;
  text-decoration: underline !important;
}
