.section {
  background-color: var(--kale-4);
  padding: var(--margin-xxl) 0;
}

@media (--viewport-lg) {
  .section {
    margin-top: var(--margin-xxl);
  }

  .mobileBreakline {
    display: none;
  }
}
