@use '@farmersdog/corgi-x/src/styles/constants-legacy.scss' as *;
@use 'styles/mixins.scss' as *;
@use 'constants/fonts.scss' as *;

.Menu {
  @include list-reset();

  li {
    display: block;
    padding-top: $margin-sm;
    padding-bottom: $margin-sm;
    border-bottom: 1px solid $oyster-0;
    cursor: pointer;
    &:hover {
      color: $carrot-3;
    }
  }
}
