@media (--viewport-xs) {
  .node {
    margin-top: var(--margin-xl);
  }
}

@media (--viewport-md) {
  .node {
    margin-top: var(--margin-xxl);
  }
}
