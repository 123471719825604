@use '@farmersdog/corgi-x/src/styles/constants-legacy.scss' as *;

.container {
  background-color: $chickpea-1;
  width: 100%;
  padding: 72px 0;

  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;

  &.lg {
    padding: 96px 0;
  }
}

.accessibilityTheme {
  background-color: var(--oyster-0);
}

.stars {
  margin-bottom: 20px;
}

.lg .stars {
  margin-bottom: 17px;
}

.heading {
  margin-bottom: 9px;
}

.carousel {
  margin-top: 18px;
  max-width: 1325px;

  li {
    display: flex;
  }
}

.lg .carousel {
  padding: 0 10px;
}
