.container {
  height: auto;
  min-height: 100vh;
  width: 100%;
  text-align: center;
  flex-wrap: nowrap;
}

.bottomContainer {
  &.md {
    /* 74px is taken from the size used for the Logo element */
    height: calc(74px + var(--margin-md));
  }

  &.xs {
    position: relative;
    flex: 1;
    max-height: 20vh;
    min-height: 100px;
  }
}

.logoContainer {
  &.xs {
    margin-top: var(--margin-xs);
  }

  &.md {
    margin-top: var(--margin-md);
  }
}

.contentContainer {
  flex: 0;
}

.subheadingContainer {
  position: relative;
}

.formContainer {
  max-width: 327px;
  width: calc(100vw - var(--margin-sm) * 2);
  position: relative;
}

.hidden {
  visibility: hidden;
}

.button {
  width: 100%;
}

.dogPictureAbsoluteContainer {
  position: absolute;

  &.xs {
    top: 0;
    height: calc(100% - var(--margin-md));
    width: 100%;
  }

  &.md {
    left: -125px;
    bottom: -125px;
  }
}

.dogPicture {
  &.xs {
    height: 100%;
  }

  &.md {
    height: 150px;
  }
}

.thanksText {
  position: absolute;
  left: 0;
  width: 100%;
}

.noWrap {
  white-space: nowrap;
}