.diagramBackground {
  position: relative;
  text-align: left;
  width: 100%;
  background: linear-gradient(white, var(--oyster-0));
  padding: var(--margin-xl) 0;
  z-index: -1;
}

.diagramBackground.withNav {
  padding: var(--margin-lg) 0;
}

.main {
  margin: auto;
  padding: 0;
  z-index: 10;
}

.nav {
  margin-top: var(--margin-xxl);
}

.content {
  padding: var(--margin-md);
}

.diagramImage {
  width: 160px;
  height: 160px;
  margin-left: var(--margin-md);
}

.xsTop {
  margin: 0;
  margin-top: var(--margin-xs);
}

.smTop {
  margin: 0;
  margin-top: var(--margin-sm);
}

.mdTop {
  margin: 0;
  margin-top: var(--margin-md);
}

.supplements {
  text-transform: capitalize;
}

.unfocusedRecipe {
  /* Needs important to overide Corgi button style */
  color: var(--charcoal-1) !important;
}

@media (--viewport-md) {
  .nav {
    margin-top: var(--margin-3xl);
  }

  .diagramBackground {
    text-align: center;
    padding: var(--margin-xxl) 0;
  }

  .diagramImage {
    margin-left: 0;
    width: 200px;
    height: 200px;
  }

  .content {
    padding-left: var(--margin-xl);
    padding-right: var(--margin-xl);
    padding-bottom: var(--margin-xl);
  }

  .recipeNavButtons {
    /* Needs important to overide Corgi button style */
    font-size: 21px !important;
  }
}
