.section {
  padding-top: 4rem;
  padding-bottom: var(--margin-xl);
  margin: 0 auto;
  background-position: bottom center;
  background-size: 100%;
  background-repeat: no-repeat;
  position: relative;
  background-color: var(--white);
}

.section.accessibilityTheme {
  background-color: var(--oyster-0);
  padding: var(--margin-3xl) 0 var(--margin-sm);
}

.button {
  margin: 1.5rem 0;
}

@media (--viewport-lg) {
  .section.accessibilityTheme {
    padding-bottom: 0;
  }
}
