.container {
  display: flex;
  justify-content: center;
  flex-wrap: wrap-reverse;
  gap: var(--tfd-spacing-sm);
}

.button {
  width: 150px;
  text-align: center;
}

@media (--viewport-md) {
  .container {
    flex-wrap: nowrap;
    justify-content: flex-start;
  }
}
