.container {
  background: var(--chickpea-1);
  padding-top: var(--tfd-spacing-xxl);
  padding-bottom: var(--tfd-spacing-xl);
}

.picture {
  display: flex;
  flex-direction: row;
  flex-wrap: nowrap;
  position: relative;
  flex: 1;
  overflow: visible;
  justify-content: center;
}

.before {
  left: 10%;
  bottom: 0;
}

.after {
  left: -10%;
  bottom: 0;
}

.oysterBackground {
  background-color: var(--oyster-0);
}

.ctaPadding {
  padding: var(--margin-md) var(--margin-md);
}

@media (--viewport-lg) {
  .container {
    padding-bottom: var(--tfd-spacing-xxl);
  }

  .ctaPadding {
    padding: 0 var(--margin-md);
  }

  .before {
    left: 0;
  }

  .after {
    left: -5%;
  }
}
