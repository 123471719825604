@use '@farmersdog/corgi-x/src/styles/constants-legacy.scss' as *;

.container {
  margin-top: auto;
  background-color: $oyster-0;
  color: $kale-3;
  padding: 20px 0;

  @media print {
    display: none;
  }
}

.container.extra-padding {
  padding-bottom: 130px;
}

.logos {
  padding: 20px 10px;
  text-align: center;
}

.logos img,
.logos svg {
  display: inline-block;
  margin: 20px;
  vertical-align: middle;
}
