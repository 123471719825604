.wrapper {
  margin: auto;
  max-width: 1440px;
  min-height: 390px;
  width: 100%;
}

.container {
  align-items: center;
  display: flex;
  flex-direction: column;
  gap: var(--tfd-spacing-xl);
  justify-content: center;
}

.logos {
  align-items: center;
  display: flex;
  flex-direction: row;
  gap: var(--tfd-spacing-md);
  justify-content: space-between;
  max-width: 1066px;
  width: 100%;
}

.quote {
  margin: auto;
  max-width: 657px;
  text-align: center;
}

.image {
  filter: grayscale(1);
  object-fit: contain;
  opacity: 0.25;
  transition: all 1000ms;
  width: 100%;
}

.active {
  filter: grayscale(0);
  opacity: 1;
}
