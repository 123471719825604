.vetsKnowBestWrapper {
  width: calc(100% - 2 * 16px) !important;
  max-width: 1440px;
}

.testimonialContainer {
  margin-top: var(--margin-lg);
  text-align: center;
}

.quote {
  margin-top: var(--margin-md);
  margin-bottom: var(--margin-lg);
}

.placeholderImage {
  max-width: 100%;
  padding: 0 var(--margin-xs);
}

.underlinedSubject {
  position: relative;
}

.underline {
  bottom: -8px;
  left: 4px;
  position: absolute;
}

.certification {
  flex-wrap: nowrap;
  gap: 20px;
  padding: var(--margin-md);
}

.certificationContainer {
  display: flex;
  flex-direction: row;
  gap: 20px;

  &.withBottomBorder {
    border-bottom: 1px solid var(--charcoal-0);
    padding-bottom: var(--margin-md);
  }
}

.certificationLabel {
  font-weight: 500;
}

.vetProfessional {
  /* TODO: change to var once the color is added to the palette. */
  background: #b5d3ba;
  border-radius: calc(var(--tfd-border-radius-md) * 2);
  margin-top: 32px;
  padding: 12px;
}

.vetPortalLink {
  color: var(--kale-3);
  font-weight: 700;
  text-decoration: underline;
}

.associationLogos {
  align-items: center;
  margin-top: 32px;

  img {
    object-fit: contain;
  }
}

.iconContainer {
  align-items: center;
  display: flex;
  justify-content: center;
  min-width: 45px;
  
  svg {
    width: 36px;
    height: 36px;
  }
}

@media (--viewport-lg) {
  .vetsKnowBestWrapper {
    align-items: center;
    display: flex;
    flex-direction: column;
  }

  .testimonialContainer {
    max-width: 800px;
  }

  .vetProfessional {
    display: flex;
    gap: 4px;
    justify-content: center;
    margin-top: var(--margin-xxl);
    max-width: 641px;
    width: 100%;
  }

  .associationLogos {
    margin-top: var(--margin-xl);
  }

  .quote {
    margin-top: var(--margin-xl);
    margin-bottom: var(--margin-xl);
  }

  .quoteText {
    font-size: 28px;
  }

  .certification {
    flex-direction: column;
  }

  .certificationContainer {
    flex-direction: column;
    max-width: 218px;
    
    &.withBottomBorder {
      border-bottom: none;
      padding-bottom: 0;
    }
  }

  .iconContainer {
    svg {
      width: 48px;
      height: 48px;
    }
  }
}
