.accessibilityThemeCta {
  margin: var(--margin-sm) 0 var(--margin-lg);
}

.breakline {
  display: none
}

/* SectionBowl is using a deprecated "Viewport" component for building the layout, which internally uses this 992px media break point*/
/* which we currently do not have as a css variable (--viewport-*) so that's why we add this line. */
@media(min-width: 992px){
  .breakline {
    display: block;
  }
}

@media(--viewport-lg) {
  .breakline {
    display: none;
  }
}
