.heroContainer {
  position: relative;
}

.heroContainer .termsBanner {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  z-index: 10;
}
