.buttons {
  align-items: center;
  min-height: 44px;
  margin-right: 0;
  margin-left: 0;
  display: grid;
  grid-template-columns: 1fr;
}

.recover {
  text-align: center;
  margin-bottom: var(--margin-md);
}

.startOver {
  text-align: center;
}

.title {
  margin-bottom: var(--margin-md);
}

@media (--viewport-md) {
  .buttons {
    grid-template-columns: 1fr 1fr;
  }
  .recover {
    text-align: right;
    order: 2;
    margin-bottom: 0;
  }
  .startOver {
    text-align: left;
    order: 1;
  }
}
