.benefitIcon {
  vertical-align: middle;
  margin-right: var(--margin-sm);
  width: var(--margin-lg);
  height: var(--margin-lg);
  z-index: 1;
}

.benefitLayout {
  display: flex;
  justify-content: center;
}

.benefitSmallWrapper {
  display: flex;
  align-items: flex-start;
  flex-direction: column;
  justify-content: center;
}

.benefitLargeWrapper {
  display: flex;
  flex-direction: row;
  justify-content: center;
}

.benefitLarge {
  display: none;
  padding: var(--margin-xs) 0;
  white-space: nowrap;
}

.benefitSmall {
  padding: var(--margin-xs) 0;
  white-space: nowrap;
}

.benefitSmallMobile {
  padding: 3px 0;
}

.benefitBody {
  text-align: left;
}

.benefitBox {
  position: relative;
  border: 1px solid var(--oyster-0);
  margin-left: var(--margin-sm);
  margin-right: var(--margin-sm);
  width: 300px;
}

.headline {
  margin-bottom: var(--margin-sm);
}

.benefitLargeDescription {
  margin-bottom: var(--margin-sm);
  line-height: 1;
}

@media (--viewport-lg) {
  .benefitSmallWrapper {
    display: none;
  }

  .benefitLarge {
    display: inline;
  }

  .benefitIcon {
    vertical-align: middle;
    margin-right: 0;
    width: var(--margin-xxl);
    height: var(--margin-xxl);
    position: relative;
    top: calc(var(--margin-xxl) / 2);
  }

  .headline {
    margin-top: var(--margin-xl);
  }
}
