.Details {
  --arrow-width: 16px;
  margin: var(--tfd-spacing-sm) 0;
}

.open:not(:last-child) {
  margin-bottom: var(--tfd-spacing-md);
  border-bottom: 1px solid var(--oyster-0);
}

.open.borderless {
  border: none;
}

.open .summary {
  color: var(--carrot-3);
}

.open .summary .arrowRight {
  transform: rotate(90deg);
}

.open .summary:hover .arrowRight {
  transform: rotate(90deg);
}

.summary {
  display: flex;
  gap: 2px;
  align-items: center;
  text-align: left;
  cursor: pointer;

  &:hover {
    color: var(--carrot-3);
  }

  &:hover .arrowRight {
  }
}

.summary h3 {
  display: inline-block;
}

.summary .arrowRight {
  position: relative;
  top: 1px;

  display: inline-block;
  min-width: var(--arrow-width);
  width: var(--arrow-width);
  height: var(--arrow-width);
  transition: transform 0.15s ease-in-out;

  fill: var(--charcoal-3);
}

.arrowRight.white {
  fill: white;
}

.content {
  margin: 0 var(--tfd-spacing-sm);
  padding-bottom: var(--tfd-spacing-xxs);
  padding-left: var(--tfd-spacing-xxs);
}
