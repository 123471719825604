@use '@farmersdog/corgi-x/src/styles/constants-legacy.scss' as *;

.CircleButton.Button.default-alternate {
  padding-right: 0;
  padding-left: 0;
  width: 30px;
  height: 30px;
  line-height: 30px;
  border-radius: 30px;
  > .Button-label {
    top: 0;
  }
  > .Button-label > svg {
    top: -2px;
    margin-right: 0;
    width: 18px;
    height: 18px;
    transition: fill 0.1s ease-in-out;

    fill: white;
  }
}
