.table {
  width: 100%;
  border-collapse: collapse;
  text-align: left;
}

.caption {
  border: 0;
  clip: rect(0 0 0 0);
  height: 1px;
  margin: -1px;
  overflow: hidden;
  padding: 0;
  position: absolute;
  width: 1px;
}

.tr {
  height: var(--margin-xl);
}

.tr + .tr {
  border-top: 1px solid var(--oyster-0);
}


.th, .name {
  padding-left: var(--margin-sm);
}

.th {
  padding-top: var(--margin-sm);
  padding-bottom: var(--margin-sm);
}

.headerRow {
  background: var(--oyster-0);
}

.name {
  width: 67%;
}

.percentage {
  width: 33%;
}

.headerText {
  margin: 0;
}

@media (--viewport-md) {
  .th, .name {
    padding-left: var(--margin-md);
  }
}
