.container {
  position: relative;
}

.backgroundImage {
  object-fit: cover;
  max-width: 100%;
}

.cta {
  position: absolute;
  bottom: var(--margin-md);
  left: 50%;
  transform: translateX(-50%);
}

@media (--viewport-lg) {
  .cta {
    display: none !important;
  }
}
