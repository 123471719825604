:root {
  --pet-name-ampersand-width: 36px;
}

.input {
  margin-bottom: var(--margin-lg);
}

@media (--viewport-xs) {
  .input {
    max-width: 210px;
  }
}

@media (--viewport-md) {
  .input {
    max-width: 276px;
  }
}

.notFirst {
  display: inline-block;
}

@media (--viewport-xs) {
  .notFirst {
    margin-left: calc(-1 * var(--pet-name-ampersand-width));
  }
}

@media (--viewport-md) {
  .notFirst {
    margin-left: 0;
  }
}

@media (--viewport-xs) {
  .first {
    display: inline-block;
    margin-left: calc(-1 * var(--pet-name-ampersand-width));
  }

  .first .input {
    /* -4px fixes rounding issues */
    margin-left: calc(var(--pet-name-ampersand-width) - 4px);
  }
}

@media (--viewport-md) {
  .first {
    margin-left: 0;
  }

  .first .input {
    margin-left: 0;
  }
}
