@use '@farmersdog/corgi-x/src/styles/constants-legacy.scss' as *;

.ActivityIndicator {
  display: inline-block;
  height: 18px;
  text-align: center;
  vertical-align: middle;
}

.ActivityIndicator.block {
  display: block;
  width: 100%;
}

.ActivityIndicator > div {
  display: inline-block;
  width: 18px;
  height: 18px;
  animation: sk-bouncedelay 1.4s infinite ease-in-out both;
  border-radius: 100%;
  background-color: $carrot-2;
}

.ActivityIndicator.inverted > div {
  background-color: $white;
}

.ActivityIndicator > .ActivityIndicator-bounce1 {
  animation-delay: -0.32s;
}

.ActivityIndicator > .ActivityIndicator-bounce2 {
  animation-delay: -0.16s;
}

@keyframes sk-bouncedelay {
  0%,
  80%,
  100% {
    transform: scale(0);
  }
  40% {
    transform: scale(1);
  }
}
