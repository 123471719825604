@use '@farmersdog/corgi-x/src/styles/constants-legacy.scss' as *;

.container {
  text-align: center;
  display: flex;
  flex-wrap: wrap;
  align-items: center;
  flex-direction: column;
  padding-top: $margin-lg;
}

.lg.container {
  text-align: left;
  padding: $margin-xxl 0;
  flex-wrap: nowrap;
  flex-direction: row;
}

.content {
  width: 50%;
  flex-shrink: 0;
  padding-right: $margin-lg;
}
