.article-24 {
  font-family: var(--tfd-font-family-serif);
  font-size: 24px;
  line-height: 30px;
  font-weight: 400;
}

.spaced-12,
.spaced-16 {
  font-family: var(--tfd-font-family-text);
  line-height: 18px;
  text-transform: uppercase;
  letter-spacing: 2px;
}

.spaced-12 {
  font-size: 12px;
}

.spaced-16 {
  font-family: var(--tfd-font-family-text);
  font-size: 16px;
  line-height: 22px;
  text-transform: uppercase;
  letter-spacing: 2px;
}

.heading-36 {
  font-family: var(--tfd-font-family);
  font-stretch: normal;
  letter-spacing: 0.5px;
  font-size: 36px;
  line-height: 42px;
}

.spaced-16 {
  font-size: 16px;
}
