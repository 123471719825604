.password {
  margin-top: var(--margin-sm);
}

.captcha {
  display: flex;
  justify-content: center;
}

.submitButton {
  width: 100%;
  margin-top: var(--margin-lg);
}

.resetPassword {
  display: block;
  margin-top: var(--margin-md);
  text-align: center;
}

.divider {
  margin-top: var(--margin-lg);
}

.ctaHeader {
  margin-top: var(--margin-lg);
  margin-bottom: 0;
}

.signup {
  width: 100%;
  margin-top: var(--margin-md);
}

.form {
  text-align: start;
  margin-bottom: var(--margin-lg);
}
