:root {
  --segmented-control-padding: calc(var(--margin-sm) - 1px);
}

.SegmentedControl {
  position: relative;
  display: inline-block;
  outline: none;
  cursor: pointer;
  line-height: 1rem;
}

.SegmentedControl:not(.stacked) {
  margin: 5px;
}

.SegmentedControl.stacked span {
  margin-left: -1px;
  border-color: var(--carrot-2);
  border-radius: 0;
  color: var(--carrot-2);
}

.SegmentedControl.stacked:first-child span {
  margin-left: 5px;
  border-top-left-radius: 20px;
  border-bottom-left-radius: 20px;
}
.SegmentedControl.stacked:last-child span {
  margin-right: 5px;
  border-top-right-radius: 20px;
  border-bottom-right-radius: 20px;
}

.SegmentedControl input {
  position: absolute;
  left: -10000px;
  height: 0;
  appearance: none;
}

.SegmentedControl span {
  display: inline-block;
  padding: var(--segmented-control-padding) 20px
    var(--segmented-control-padding) 20px;
  border: 1px solid var(--charcoal-1);
  border-radius: 24px;
  background: white;
  color: var(--charcoal-3);
  transition: background-color 0.075s linear, color 0.075s linear,
    border-color 0.075s linear;
}

.SegmentedControl input:focus + span {
  outline: solid 2px rgba(0, 103, 244, 0.247);
  outline: auto 5px -webkit-focus-ring-color;
}

.SegmentedControl input:checked + span {
  border-color: var(--carrot-2);
  background-color: var(--carrot-2);
  color: white;
}

@media (hover: hover) {
  .SegmentedControl input:not(:checked) + span:hover {
    border-color: var(--carrot-2);
    color: var(--carrot-2);
  }
}
