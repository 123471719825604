.circleButton {
  padding-right: 0;
  padding-left: 0;
  width: 60px;
  height: 60px;
  line-height: 30px;
  border-radius: 30px;
  background-color: hsla(220, 1%, 59%, 0.6);
}

.circleButton:hover {
  background-color: var(--carrot-2);
}

.circleButton > svg {
  top: -2px;
  width: 18px;
  margin: 0 auto;
  height: 18px;
  transition: fill 0.1s ease-in-out;
  fill: white;
}
