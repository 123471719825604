.container {
  display: flex;
  flex-wrap: wrap;
  justify-content: center;
  margin-top: var(--margin-sm);
}

@media (--viewport-md) {
  .container {
    margin-top: var(--margin-md);
  }
}

.petName {
  margin-left: calc(var(--page-margin-sm) / 2);
  margin-right: calc(var(--page-margin-sm) / 2);
  text-align: center;
}

.activePetName {
  color: var(--persimmon-3) !important;
}

.inactivePetName {
  color: var(--charcoal-1) !important;
}
