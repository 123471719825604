.nutritionFactsModal {
  padding: var(--margin-md) var(--margin-lg);

  @media (--viewport-lg) {
    padding: var(--margin-xxl) var(--margin-4xl);
  }

  &.turkey {
    background-color: var(--chickpea-0);
  }

  &.chicken {
    background-color: var(--kale-0);
  }

  &.pork {
    background-color: var(--blueberry-0);
  }
}
