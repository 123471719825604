.container {
  position: relative;
  height: 600px;
  text-align: center;
}

.header {
  position: absolute;
  left: 9%;
  bottom: 28.5%;
  text-align: left;

  &.lg {
    width: auto;
    left: 8%;
    bottom: 15%;
  }
}

.background {
  height: 600px;
  width: 100%;

  // IE doesn't support
  @supports (object-fit: cover) and (object-position: 16% 50%) {
    width: 100%;
    object-fit: cover;
    object-position: 16% 50%;
  }
}
