@use '@farmersdog/corgi-x/src/styles/constants-legacy.scss' as *;
@use 'styles/constants.scss' as *;

.container {
  display: flex;
  flex-direction: column;
  align-items: center;
  background: $chickpea-1;
  padding: 36px 30px 48px;

  .quotesCard + .quotesCard {
    margin-top: 36px;
  }

  &.lg {
    flex-direction: row;
    justify-content: center;
    padding: 96px 30px;

    .quotesCard {
      align-self: flex-start;
    }

    .quotesCard + .quotesCard {
      margin-top: 0;
      margin-left: 48px;
    }
  }
}

.quotesCard {
  max-width: 315px;
  padding: 37px 24px 36px;

  blockquote {
    margin: 0;
  }

  figcaption {
    margin: 12px 0;
  }

  cite {
    font-style: normal;
    margin: 24px 0;
  }
}

.accessibleDesktopContainer {
  display: flex;
  flex-direction: row;
  justify-content: center;
  padding: 0px 30px;
  text-align: center;

  .quotesCard {
    max-width: $cta-desktop-width;
  }

  .quotesCard + .quotesCard {
    margin-top: 0;
    margin-left: 48px;
  }
}

.cardMargin {
  margin-top: $margin-lg;
}
