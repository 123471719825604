.container {
  line-height: 0;
  text-align: center;
  margin-top: var(--margin-md);
}

.text {
  position: relative;
  font-weight: normal;
  display: inline-block;
  padding: var(--margin-sm);
  border: 1px solid var(--carrot-2);
  background: var(--white);
  color: var(--carrot-2);
}

.text:after,
.text:before {
  position: absolute;
  bottom: 100%;
  left: 50%;

  width: 0;
  height: 0;
  border: solid transparent;
  content: ' ';
  pointer-events: none;
}

.text:after {
  margin-left: -10px;
  border-width: 10px;
  border-color: transparent;
  border-bottom-color: var(--white);
}

.text:before {
  margin-left: -11px;
  border-width: 11px;
  border-color: transparent;
  border-bottom-color: var(--carrot-2);
}
