@use '@farmersdog/corgi-x/src/styles/constants-legacy.scss' as *;

.grid {
  display: grid;
  grid-template-columns: 1fr;
  grid-template-rows: auto;
  row-gap: $margin-md;
  margin-bottom: $margin-md;
}

.grid > .grid {
  margin-bottom: 0;
}

.lg.grid {
  grid-template-columns: 1fr 1fr;
  column-gap: $gutter-lg;
  row-gap: $margin-md;
}

.xl.grid {
  grid-template-columns: 1fr 1fr;
  column-gap: $gutter-xl;
  row-gap: $margin-md;
}
