.container {
  display: flex;
  flex-direction: column;
  justify-content: center;
  margin-top: var(--margin-xl);
  margin-bottom: var(--margin-md);
}

.text {
  text-align: center;
  color: var(--charcoal-3);
  margin: 0;
}

.image {
  height: 114px;
  margin-bottom: var(--margin-lg);
}

@media (--viewport-md) {
  .image {
    height: 120px;
    margin-bottom: var(--margin-md);
  }
  .container {
    margin-bottom: var(--margin-xl);
  }
}
