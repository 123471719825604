@use '@farmersdog/corgi-x/src/styles/constants-legacy.scss' as *;
@use 'constants/fonts.scss' as *;

.pac-container {
  box-sizing: border-box;
  border-radius: 4px;
  font-family: $heading-sans;
  font-size: 16px;
  letter-spacing: 0.5px;
  font-weight: 400;

  border: 1px solid $kale-3;
  margin-top: 2px;

  &::after {
    margin: $margin-sm;
    margin-left: 0;
    opacity: 0.3;
  }

  .pac-item {
    font-family: $heading-sans;
    font-size: 16px;
    line-height: 1.63;
    letter-spacing: 0.5px;
    height: 50px;
    padding: $margin-sm;
    color: $kale-3;
    border: none;
  }

  .pac-item + .pac-item {
    border-top: 1px solid $oyster-0;
  }

  .pac-item:hover {
    background: none;
    color: $carrot-2;
  }

  /** remove default google styles */
  .pac-item-query {
    color: inherit;
    font-size: inherit;
  }

  /** remove default google styles */
  .pac-matched {
    font-weight: inherit;
  }

  .pac-item-selected {
    background: $carrot-3;
  }

  .pac-icon {
    display: none;
  }
}
