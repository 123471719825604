@use '@farmersdog/corgi-x/src/styles/constants-legacy.scss' as *;

.container {
  margin: $margin-xl 0;
}

.lg.container {
  margin: $margin-3xl 0;
}

.content {
  grid-column: 1 / -1;
}

.lg .content {
  grid-column: 1 / 5;
}

.lg .image {
  grid-column: 6 / -1;
}

.lg .cta {
  grid-column: 1 / -1;
}
