.container {
  width: 100vw;
}

.navigation {
  padding: var(--margin-xs) var(--margin-sm);
}

.large {
  display: none;
}

.small {
  display: block;
}

@media (--viewport-lg) {
  .navigation {
    padding: var(--margin-sm) var(--margin-xl);
  }

  .large {
    display: block;
  }

  .small {
    display: none;
  }
}
