.container {
  margin-bottom: var(--tfd-spacing-xxl);
  padding-top: var(--tfd-spacing-xxl);
}

.nav {
  position: sticky;
  top: calc(var(--tfd-appnav-height) + var(--tfd-spacing-xxl));
}

.sectionHeading:not(:first-of-type) {
  margin-top: var(--tfd-spacing-xl);
}

.scrollElement {
  position: relative;
  height: 0px;
  top: calc((var(--tfd-appnav-height) + var(--tfd-spacing-md)) * -1);
}
.scrollElement:first-child {
  top: calc((var(--tfd-appnav-height) + var(--tfd-spacing-xxl)) * -1);
}
.cta {
  margin-top: 1.5em;
  min-width: 100px;
}
