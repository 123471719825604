.quoteTitle {
  color: black;
  letter-spacing: 4px;
  text-transform: uppercase;
}

.list {
  margin: 0;
  padding: 0;
  list-style: none;
}

.list li {
  display: flex;
  gap: var(--margin-md);
  align-items: flex-start;
}

.list svg {
  width: 16.6px;
  flex-shrink: 0;
}

.modalContent {
  position: relative;
  background-color: #f0f3f0;
  padding: 130px 60px 53px 60px;
}

.blockquote {
  margin: 0;
  background: white;
  padding: var(--margin-md);
  margin-bottom: var(--margin-lg);
}

.closeButton {
  top: 53px;
  right: 60px;
  position: absolute;

  &.bottom {
    top: auto;
    bottom: 53px;
  }
}

.button {
  width: 188px;
}

.image {
  height: 100%;
  object-fit: cover;
}

/* Changes the z-index of the lightbox */
:has(.backdrop) {
  z-index: calc(var(--tfd-appnav-z-index) + 1) !important;
}
