@use '@farmersdog/corgi-x/src/styles/constants-legacy.scss' as *;

$border-height: 3px;

.container {
  margin-top: $margin-lg;
  margin-bottom: $margin-lg;
}

.tabs {
  list-style-type: none;
  width: 100%;
  display: flex;
  padding: 0;
  border-bottom: $border-height solid grey;
}

.tab {
  cursor: pointer;
}

.tab-container {
  padding-bottom: $margin_sm;
  flex-grow: 1;
  text-align: center;
  position: relative;
}

.active:after {
  position: absolute;
  bottom: -$border-height;
  left: 0;
  content: "";
  height: $border-height;
  width: 100%;
  background-color: $carrot-2;
}
