@use '@farmersdog/corgi-x/src/styles/constants-legacy.scss' as *;

.container {
  position: sticky;
  top: 0px;
  padding: $margin-sm 0;
  z-index: 4;
  background: $white;
  box-shadow: 0 6px 4px -4px rgba(0, 0, 0, 0.2);
  width: 100%;
}

.lg.container {
  top: 70px;
}

.pet-selector {
  text-align: left;
  flex: 0 10 120px;
}

.content-row {
  display: flex;
  flex-wrap: nowrap;
  align-items: center;
}

.header-summary {
  display: flex;
  flex-wrap: nowrap;
  align-items: center;
  height: 100%;
  flex: 1 0 auto;
  margin-left: $margin-md;
}

.header-summary > *:first-of-type {
  margin: 0;
}

.header-summary > * + * {
  margin-left: $margin-sm;
}

.header-summary > * {
  flex: 1 0;
  white-space: nowrap;
}

.link {
  display: inline-block;
  margin-right: $margin-md;
  padding: 0;
  background: $white;
  cursor: pointer;
  position: relative;
  appearance: none;
}

.link[disabled] {
  cursor: default;
}

.link.active {
  cursor: default;
  color: $carrot-2;
}

.carousel {
  flex: 0 1 auto;
}

.carousel svg {
  fill: black;
}

.inactiveLink {
  color: $charcoal-1 !important;
}
