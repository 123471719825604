@use '@farmersdog/corgi-x/src/styles/constants-legacy.scss' as *;

.card {
  display: flex;
  flex-direction: column;
  margin: 0 0 $margin-sm 0;
  max-width: $column-xl;
  flex: 1;
  border-radius: 0;
}

.rounded-card {
  border-radius: $margin-md;
}

.user {
  display: flex;
  flex-direction: row;
  padding: $margin-md;
  padding-bottom: $margin-sm;
}

.screenName {
  margin-left: $margin-sm;
}

.image {
  width: 100%;
  height: $column-xl;
  object-fit: cover;
}

.content {
  padding: $margin-md;
  text-align: left;
}

.quote {
  margin: 0;
}
