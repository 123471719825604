@import './slide-up-in.css';
@import './slide-up-out.css';
@import './fade-in.css';
@import './fade-out.css';

.entering {
  animation-timing-function: var(--animation-standard-timing-function);
  animation-name: slide-up-in, fade-in;
  animation-duration: var(--animation-standard-duration);
  animation-fill-mode: forwards;
}

.exiting {
  animation-timing-function: var(--animation-standard-timing-function);
  animation-name: slide-up-out, fade-out;
  animation-duration: var(--animation-standard-duration);
  animation-fill-mode: forwards;
}
