.buttons {
  margin-top: var(--margin-xl);
  display: flex;
  justify-content: space-between;
  align-items: center;
}

.container {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
}

.badBoyImage {
  max-width: 200px;
  margin-top: var(--margin-lg);
}

.errorMessage {
  margin-bottom: var(--margin-lg);
}
