@use '@farmersdog/corgi-x/src/styles/constants-legacy.scss' as *;

.Instagram {
  overflow: hidden;
  border-radius: 8px;
  background: $white;
  box-shadow: 0 3px 5px rgba(0, 0, 0, 0.1);
  margin: 0;
  &-ratio {
    position: relative;

    &:before {
      display: block;
      padding-top: calc(696 / 780) * 100%;
      width: 100%;
      content: '';
    }

    img {
      position: absolute;
      top: 0;
      right: 0;
      bottom: 0;
      left: 0;

      display: block;
      width: 100%;
      height: 100%;

      object-fit: cover;
    }
  }

  &-content {
    padding: 1rem;
    padding-top: 0;
  }

  &-userName {
    display: inline;
  }
}
