.headingContainer,
.questionContainer {
  grid-column: 1/-1;
}

.headingContainer {
  margin-bottom: var(--tfd-spacing-lg);
}

@media (--viewport-lg) {
  .headingContainer {
    grid-column: 1/2;
  }

  .questionContainer {
    grid-column: 4/12;
  }
}
