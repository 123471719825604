.inline {
  display: inline;
}

.Fade {
  transition: opacity 100ms linear;
  opacity: 0;
}

.Fade.entered {
  opacity: 1;
}

.Fade.exiting {
  opacity: 0;
}

.Fade.exited.invisible {
  visibility: hidden;
}

.Appear {
  transition: all 300ms cubic-bezier(0.19, 1, 0.22, 1);
  transition-property: opacity, transform;
  transform-origin: top center;
  transform: scale(1);
  opacity: 1;
}

.Appear.entering,
.Appear.entered {
  transform: scale(1);
  opacity: 1;
}

.Appear.exiting,
.Appear.exited {
  transform: scale(0.8);
  opacity: 0;
}
