@media (--viewport-xs) {
  .treatInfo {
    margin-left: 80px;
    text-align: left;
  }

  .singleTreatContainer {
    padding-left: var(--margin-xs);
    padding-right: var(--margin-xxs);
    padding-top: var(--margin-xs);

    picture {
      bottom: -11px;
      position: absolute;

      img {
        max-width: 100px;
      }
    }
  }

  .multiTreatsContainer {
    margin-top: var(--margin-md);

    .toggleBoxContent {
      flex: 1;
      display: flex;
      flex-direction: row;
      margin-right: 0;
      max-height: 120px;
      position: relative;

      picture {
        bottom: -11px;
        position: absolute;

        img {
          max-width: 100px;
        }
      }
    }

    .treatItem {
      background-color: var(--white);
      flex: 1;
      max-width: var(--treats-max-width);
      padding-top: 0;
      width: 100%;

      > div > div > div:first-child {
        overflow: visible;
      }

      > div {
        padding-top: var(--margin-xs);
        padding-bottom: var(--margin-sm);
        padding-left: var(--margin-xxs);
      }
    }

    .treatItemContainer {
      margin-bottom: var(--margin-md);

      &:last-child {
        margin-bottom: 0;
      }
    }
  }
}

@media (--viewport-sm) {
  .toggleBoxContent {
    picture {
      img {
        max-width: 115px;
      }
    }
  }

  .treatInfo {
    margin-left: 110px;
    text-align: left;
  }

  .singleTreatContainer {
    padding: var(--margin-sm) var(--margin-sm) var(--margin-sm) var(--margin-md);
  }

  .multiTreatsContainer {
    .treatInfo {
      margin-left: 110px;
    }

    .treatItem {
      background-color: var(--white);
      flex: 1;
      max-width: var(--treats-max-width);
      padding-top: 0;
      width: 100%;

      > div {
        padding-top: var(--margin-sm);
        padding-bottom: var(--margin-sm);
        padding-left: var(--margin-sm);
      }
    }
  }
}

@media (--viewport-md) {
  .singleTreatContainer {
    margin-top: var(--margin-lg);
  }

  .multiTreatsContainer {
    margin-top: var(--margin-lg);

    .treatInfo {
      margin-left: 130px;
    }

    .toggleBoxContent {
      picture {
        bottom: -12px;
        img {
          max-width: 131px;
        }
      }
    }

    .treatItemContainer {
      margin-bottom: 40px;

      &:last-child {
        margin-bottom: 0;
      }
    }
  }

  .doubleTreatsContainer {
    margin-top: 56px;

    .treatItemContainer {
      margin-bottom: 52px;

      &:last-child {
        margin-bottom: 0;
      }
    }
  }
}

.treatsContainer {
  align-items: center;
  display: flex;
  flex-direction: column;
  width: 100%;
}

.treatIngredients {
  display: block;
}

.treatTitle {
  height: 29px;
  display: flex;
  align-items: center;
}

.treatNutritionFacts {
  margin-left: var(--margin-xs);
}

.singleTreatContainer {
  background: var(--white);
  border: 1px solid var(--kale-3);
  margin-top: var(--margin-md);
  max-width: var(--treats-max-width);
  position: relative;

  .toggleBoxContent {
    margin-right: var(--margin-xs);
    max-height: 120px;
    position: relative;
  }
}

.badge {
  padding: 4px 12px;
  background-color: var(--charcoal-3);
  position: absolute;
  right: 12px;
  top: -14px;
  border-radius: 2px;
  letter-spacing: 0.5px;
}
