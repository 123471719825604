.background {
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  height: 100%;

  @supports (object-fit: cover) and (object-position: 0% 50%) {
    position: initial;
    top: initial;
    left: initial;
    transform: initial;

    width: 100%;
    object-fit: cover;
    object-position: 0% 50%;
  }
}
