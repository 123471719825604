.author { 
  font-weight: 500;
}

.quote {
  font-size: 24px;
}

.rotateQuote {
  transform: rotate(180deg);
}

.mirrorQuote {
  transform: scaleY(-1);
}
