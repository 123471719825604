@use '@farmersdog/corgi-x/src/styles/constants-legacy.scss' as *;

.options-container {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
}

.option + .option {
  margin-top: $margin-sm;
}

.other-field {
  margin: $margin-md;
  margin-bottom: 0;
}

.submit-button {
  margin-top: $margin-md;
}
