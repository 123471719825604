@use '@farmersdog/corgi-x/src/styles/constants-legacy.scss' as *;

.benefitsContainer li {
  margin-left: -$margin-xs;
  margin-bottom: $margin-lg;
  display: flex;
  align-items: flex-start;
}

.icon {
  flex-shrink: 0;
  flex-basis: 36px;
}

.benefitsContainer {
  margin-top: $margin-xxl;
  padding-left: 0;
  list-style-type: none;
}

.lg {
  columns: 2;
}

.lg .underline {
  text-underline-offset: 12px;
}

.underline {
  text-decoration: underline;
  text-decoration-thickness: 4px;
  text-decoration-style: solid;
  text-decoration-color: $carrot-2;
  text-underline-offset: 25px;
}
