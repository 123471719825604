.container {
  width: 100%;
}

.navigation {
  display: flex;
  justify-content: space-between;
  padding: var(--margin-sm) var(--margin-md);
}

.navigation > * {
  flex: 1 1 0px;
}

.homeLink {
  display: flex;
  justify-content: center;
}
