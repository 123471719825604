.video {
  overflow: hidden;
  height: 512px;
}

.content {
  display: flex;
  flex-direction: column;
  max-width: 620px;
}

.quote {
  margin-bottom: var(--tfd-spacing-sm);
}

@media (--viewport-lg) {
  .video {
    height: 623px;
  }
}
