.quote {
  /* article-24 is not a valid prop for the Text component, so we need to apply it here. */
  font-size: 24px;
  line-height: 28px;
}

@media (--viewport-lg) {
  .quote {
    font-size: 28px;
    line-height: 34px;
  }
}
