@use '@farmersdog/corgi-x/src/styles/constants-legacy.scss' as *;

.title {
  margin-top: 0;
  margin-bottom: var(--tfd-spacing-sm);
}

.testimonials {
  background-color: var(--chickpea-1);
}

.quote {
  width: 75%;
}

.nameMobile {
  order: 1;
  margin-left: var(--tfd-spacing-sm);
}
.photo {
  width: 60px;
  height: 60px;
  border-radius: 43px;
}

.fullHeight {
  height: 100%;
}

.testimonialsContainer {
  align-content: space-between;
}

.slideShow {
  max-width: 95%;
  @media (min-width: $lg) {
    max-width: unset;
  }

  section[aria-label='vet testimonials gallery'] {
    div:last-child {
      float: left;

      @media (min-width: $lg) {
        float: none;
      }

      // consider adding a prop to Slideshow component if reusing this color variant
      button[aria-pressed='true'],
      button[aria-pressed='true']:focus {
        background-color: var(--kale-3);
        outline-color: var(--kale-3);
        border: none;
      }
      button[aria-pressed='false'],
      button[aria-pressed='false']:focus {
        background-color: var(--chickpea-3);
        outline-color: var(--chickpea-3);
        border: none;
      }
    }
  }
}
