@use '@farmersdog/corgi-x/src/styles/constants-legacy.scss' as *;

.container {
  background-color: $kale-3;
  color: $white;
  padding-bottom: var(--margin-lg);
  padding-top: var(--margin-xl);

  @media (min-width: $lg) {
    padding-bottom: var(--margin-xl);
  }

  @media print {
    display: none;
  }
}

h4 {
  margin: 0;
  margin-bottom: var(--margin-md);
}

.content {
  display: flex;
  flex-direction: column;
  gap: var(--tfd-spacing-lg);

}

@media (min-width: $lg) {
  .content {
    gap: var(--tfd-spacing-4xl);
  }
}

.lg .content {
  flex-direction: row;
}

.logo {
  margin: 0 auto 40px auto;
}

.lg .logo {
  padding-right: 40px;
}

.navigation,
.newsletter,
.contact {
  flex: 1;
  display: flex;
  flex-direction: column;
}

.navigation ul {
  margin: 0;
  padding: 0;
  list-style: none;
}

.navigation li:not(:last-child) {
  padding-bottom: $margin-md;
}

.contact a {
  margin-bottom: $margin-md;
  display: flex;
}

.contact svg {
  width: 15px;
  height: 15px;
  margin-right: 4px;
}

.contact .icon {
  position: relative;
  top: 1px;
}

.ancillary {
  padding: var(--tfd-spacing-lg) var(--tfd-spacing-md);
  text-align: center;
}

@media (min-width: $lg) {
  .ancillary {
    padding: var(--tfd-spacing-xl) var(--tfd-spacing-md);
  }
}

.navigationAnnotation {
  color: $kale-1;
  margin-left: var(--tfd-spacing-sm);
  cursor: default;
}
