@use '@farmersdog/corgi-x/src/styles/constants-legacy.scss' as *;

.container {
  .topSection {
    justify-items: center;
    .vetImage {
      width: 100%;
    }
    div:first-child {
      order: 1;
    }
    @media (min-width: $lg) {
      display: grid;
      grid-template-columns: 1fr;
      grid-template-columns: 1fr 1fr;
      .vetImage {
        width: 100%;
      }
      div:first-child {
        order: 0;
      }
    }

    .subtitle {
      display: block;
    }
  }
  .information {
    @media (min-width: $lg) {
      padding-left: var(--margin-xxl);
      padding-top: var(--margin-xl);
      display: block;
      order: 2;
    }
  }

  .informationList {
    margin: 0;
    padding-left: var(--margin-xs);
    list-style: none;
    li {
      margin-bottom: var(--margin-md);
    }
  }
}

.linkContainer {
  display: inline-block;
  @media (min-width: $lg) {
    display: inherit;
  }
}

.link {
  text-decoration: underline;
}
