.container {
  align-items: center;
  display: flex;
  justify-content: center;
  padding: 26px 0 36px 0;
  width: 100%;
}

.text {
  margin: 0 30px;
  max-width: 620px;
}

@media (--viewport-lg) {
  .container {
    padding: 72px 0 73px 0;
    text-align: center;
  }
}
