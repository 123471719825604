@use '@farmersdog/corgi-x/src/styles/constants-legacy.scss' as *;

.elements {
  display: flex;
  align-items: flex-end;
}

.button {
  height: $border-label-height;
  margin-left: $margin-xs;
}

.checkIcon {
  vertical-align: middle;
  margin-bottom: $margin-xs;
  margin-right: $margin-xs;
}

.submittedMessageContainer {
  margin-top: $margin-sm;
}
