@use '@farmersdog/corgi-x/src/styles/constants-legacy.scss' as *;

$curve: cubic-bezier(0.65, 0, 0.45, 1);
$delay: 0.35s;
$size: 40px;

.SuccessCheckmark-circle {
  stroke-dasharray: $size * 3;
  stroke-dashoffset: $size * 3;
  stroke-width: 2;
  stroke-miterlimit: 10;
  stroke: $carrot-2;
  fill: none;
  animation: stroke 0.6s $curve $delay forwards;
}

.SuccessCheckmark {
  width: $size;
  height: $size;
  border-radius: 50%;
  display: inline-block;
  stroke-width: 5;
  stroke: $white;
  stroke-miterlimit: 10;
  margin: 0 auto;
  box-shadow: inset 0px 0px 0px $carrot-2;
  animation: fill 0.4s ease-in-out 0.4s + $delay forwards,
    scale 0.3s ease-in-out 0.9s + $delay both;
}

.SuccessCheckmark-path {
  transform-origin: 50% 50%;
  stroke-dasharray: $size * 0.85;
  stroke-dashoffset: $size * 0.85;
  animation: stroke 0.3s $curve 0.8s + $delay forwards;
}

@keyframes stroke {
  100% {
    stroke-dashoffset: 0;
  }
}

@keyframes scale {
  0%,
  100% {
    transform: none;
  }
}

@keyframes fill {
  100% {
    box-shadow: inset 0px 0px 0px $size * 0.6 $carrot-2;
  }
}
