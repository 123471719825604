:root {
  --ComboBox-height: 36px;
  --border-height: 4px;
  --toggle-size: 32px;
  --toggle-margin: calc(var(--toggle-size) * 0.4);
  --toggle-margin: 12px;
  --delete-size: 24px;
}

.ComboBoxContainer {
  align-items: baseline;
  background: none;
  border: 0;
  border-bottom: var(--border-height) dotted var(--charcoal-1);
  box-sizing: border-box;
  cursor: pointer;
  display: inline-flex;
  height: var(--form-field-height);
  justify-content: space-evenly;
  margin-left: var(--margin-sm);
  margin-right: var(--margin-sm);
  margin-top: var(--margin-md);
  max-width: 80vw;
  padding: 0 0 var(--margin-xs) 0;
  position: relative;
}

.ComboBoxContainer.focused {
  border-bottom: var(--border-width) solid var(--carrot-3);
  outline: none;
}

.ComboBoxContainer button {
  background: none;
  border: 0;
  padding: 0;
}

.ComboBoxContainer:focus {
  outline: none;
}

.ComboBoxContainer:hover:not([disabled]),
.ComboBoxContainer.focused {
  border-bottom: var(--border-height) dotted var(--carrot-3);
}

.label {
  height: 1px;
  left: -10000px;
  overflow: hidden;
  position: absolute;
  top: auto;
  width: 1px;
}

.ComboBox {
  display: flex;
  cursor: pointer;
  margin-top: 0;
}

.ComboBox-delete-btn {
  align-self: center;
  border-radius: 50%;
  color: var(--carrot-2);
  cursor: pointer;
  display: inline-block;
  height: var(--delete-size);
  position: relative;
  width: var(--delete-size);
}

.ComboBox-delete-btn:hover {
  background: var(--carrot-1);
}

.ComboBox-delete-btn svg {
  fill: var(--carrot-2);
  height: calc(var(--delete-size) / 2);
  left: 50%;
  position: absolute;
  top: 50%;
  transform: translate(-50%, -50%);
  width: calc(var(--delete-size) / 2);
}

.ComboBox-delete-btn-placeholder {
  color: var(--charcoal-1);
}

.ComboBox-delete-btn-placeholder:hover {
  background: var(--oyster-0);
}

.ComboBox-delete-btn-placeholder svg {
  fill: var(--charcoal-1);
}

.input {
  appearance: none;
  background: none;
  border: none;
  color: var(--carrot-2);
  margin: 0 var(--toggle-margin) 0 0;
  text-align: center;
  text-overflow: ellipsis;
  width: 100%;
}

input:hover {
  cursor: pointer;
}

.input:focus {
  outline: none;
}

.input::placeholder {
  height: 100%;
  text-overflow: ellipsis;
}

.ComboBoxContainer li {
  cursor: pointer;
  padding: 5px 10px;
  padding-bottom: var(--margin-xs);
  padding-top: var(--margin-xs);
}

.Select-dropDown {
  background: var(--white);
  border-bottom: 1px solid var(--oyster-0);
  border-left: 1px solid var(--oyster-0);
  box-shadow: 0 0 8px 1px rgba(0, 0, 0, 0.15);
  list-style: none;
  margin: calc(var(--border-height) * 2) 0;
  max-height: 30vh;
  left: 0;
  overflow-y: auto;
  padding: 0;
  position: absolute;
  top: calc(var(--ComboBox-height) - var(--form-field-border-width));
  width: 100%;
  z-index: 1;
}

.Select-dropDown li {
  cursor: pointer;
  padding: var(--margin-xs) 10px var(--margin-xs);
}

.Select-dropDown:empty {
  border: none;
  box-shadow: none;
}

.Select-dropDown:focus {
  outline: none;
}

.Select-dropDown li[aria-selected='true'] {
  background-color: var(--carrot-3);
  color: white;
}

.Select-dropDown::-webkit-scrollbar {
  /* equivalent to scss transparentize(var(--carrot-1), 0.4); */
  background-color: rgba(255, 193, 181, 0.6);
  border: 1px var(--carrot-3);
  border-bottom: none;
  border-top: none;
  width: 14px;
  -webkit-appearance: none;
}

.Select-dropDown::-webkit-scrollbar-thumb {
  background-clip: content-box;
  background-color: var(--carrot-3);
  border: 3px solid transparent;
  border-radius: 9px;
  min-height: 48px;
}

.Select-toggle {
  align-self: center;
  padding: calc(var(--toggle-margin) / 2);
  height: 100%;
  cursor: pointer;
}

.Select-toggle:hover svg {
  fill: var(--black);
}

.hidden {
  display: none;
}

@media (--viewport-lg) {
  .ComboBoxContainer {
    margin-top: 0;
    padding-bottom: var(--padding-lg);
  }
}
