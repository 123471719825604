.carousel {
  margin-bottom: var(--tfd-spacing-xl);

  li {
    display: flex;
  }
}

.card {
  width: 300px;
  padding: var(--tfd-spacing-lg) var(--tfd-spacing-sm) var(--tfd-spacing-xl);
  margin: 0;
  display: flex;
  flex-direction: column;
  align-items: center;
  position: relative;
  background-color: var(--white);
  border-radius: 24px;
  text-align: center;
  box-shadow: none;
}

.mask {
  margin-bottom: var(--tfd-spacing-md);
}

.quote {
  margin: 0;
  margin-bottom: var(--tfd-spacing-md);
  text-align: left;
}

@media (--viewport-sm) {
  .card {
    width: 315px;
    padding-left: var(--tfd-spacing-md);
    padding-right: var(--tfd-spacing-md);
  }
}

@media (--viewport-lg) {
  .carousel {
    margin-bottom: var(--tfd-spacing-xxl);
  }

  .card {
    width: 375px;
    padding-left: var(--tfd-spacing-xl);
    padding-right: var(--tfd-spacing-xl);
  }
}
