@use '@farmersdog/corgi-x/src/styles/constants-legacy.scss' as *;

.video-preview > div {
  grid-column: 1 / -1;
}

.heading {
  text-align: center;
  margin: $margin_3xl $margin-md $margin_xl;
}

.xs .heading {
  margin: $margin_xl $margin-md $margin_md;
}

.checklist-item {
  grid-column: span 3;
  display: flex;
  flex-direction: column;
  text-align: center;
}

.xs .checklist-item {
  grid-column: 1 / -1;
  margin-bottom: $margin_md;
}

.checklist-item picture {
  margin: 0 auto $margin_md;
}

.checklist-item picture img {
  height: 64px;
}

.cta {
  margin: $margin_4xl auto 0;
  text-align: center;
}

.xs .cta {
  margin-top: $margin_xl;
}
