@use '@farmersdog/corgi-x/src/styles/constants-legacy.scss' as *;

.image {
  width: 100%;
  margin-top: $margin-sm;
}

.lg.image {
  margin-top: 0;
}
