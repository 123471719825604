.header {
  z-index: 4;
  height: 70px;
  box-shadow: 0 0 3px #00000033;
  background: var(--white);
}

.moveHigher {
  z-index: 5;
  box-shadow: none;
}

.nav {
  margin: 0 auto;
  max-width: 800px;
  width: 100%;
}

.links {
  display: flex;
  height: 71px;
  align-items: center;
  justify-content: center;
}

.link {
  color: var(--kale-3);
}

.mobileLinks {
  margin-top: -4px;
}

.linkText {
  flex: 1 0 0px;
  width: 25%;
  text-align: center;
  display: block;
  text-overflow: ellipsis;
  white-space: nowrap;
  margin-top: -1px;
  font-size: 21px;
}

.disabled {
  color: var(--charcoal-1);
  opacity: 1;
}

.active {
  color: var(--carrot-2);
}

.desktopLogo {
  margin-right: 30px;
}

.flex {
  display: flex;
}

.headerInner {
  align-items: center;
  display: flex;
  flex-wrap: wrap;
  position: relative;
  height: 100%;
}

.logoContainer {
  display: flex;
  margin-right: auto;
  width: 60px;
}

.collapsedNavigationContainer {
  display: flex;
  flex-grow: 1;
  flex-wrap: wrap;
}

.wideNavigationContainer {
  align-self: flex-end;
  display: flex;
  flex-direction: column;
  flex-grow: 1;
  margin-left: auto;
  margin-right: auto;
  max-width: 800px;
}

.collapsedDiscountBadge {
  display: flex;
  width: 60px;
}

.progressContainer {
  display: flex;
  padding: 0 12%;
  margin-left: auto;
  margin-right: auto;
}

.wideDiscountBadge {
  width: 180px;
  margin-right: auto;
}
