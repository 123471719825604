.container {
  padding-top: var(--tfd-spacing-xl);
  background: var(--white);
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
}

.heading {
  text-align: center;
  margin-top: 0;
  margin-bottom: var(--tfd-spacing-lg);
  padding-left: 30px;
  padding-right: 30px;
}

.instagram {
  display: flex;
  flex-direction: column;
}

.accessibilityThemeContainer {
  margin: var(--margin-md) 0 var(--margin-xxl);
}

@media (--viewport-xs) {
  .heading {
    padding-left: var(--tfd-spacing-sm);
    padding-right: var(--tfd-spacing-sm);
  }
}

@media (--viewport-lg) {
  .accessibilityThemeContainer {
    margin-bottom: 0;
  }

  .container {
    padding-top: 120px;
    padding-bottom: 120px;
  }

  .heading {
    margin-bottom: var(--tfd-spacing-xl);
  }

  .instagram {
    flex-direction: row;
  }
}
