.container {
  position: relative;
}

.label {
  position: absolute;
  width: 100%;
  text-align: center;
  bottom: 25px;
  left: 0;
}
