@use '@farmersdog/corgi-x/src/styles/constants-legacy.scss' as *;

.container {
  text-align: center;
  margin: $margin-md 0;
}

.lg.container {
  margin-top: $margin-xxl;
}
