.checkbox {
  /* Width of 6 columns */
  max-width: calc(var(--column-lg) * 2);
  margin: auto;
}

.checkbox span {
  text-align: left;
  font-size: 12px;
  line-height: 1.5;
}
