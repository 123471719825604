.details {
  --arrow-width: 16px;
  margin: var(--tfd-spacing-sm) 0;
}

.summary .arrowRight {
  position: relative;
  top: 5px;
  display: inline-block;
  min-width: var(--arrow-width);
  width: var(--arrow-width);
  height: var(--arrow-width);
  transition: transform 0.15s ease-in-out;
  fill: var(--white);
}

.open .summary .arrowRight {
  transform: rotate(90deg);
}

.open .summary:hover .arrowRight {
  transform: rotate(90deg);
}

.content {
  margin: 0 18px;
  padding-bottom: var(--tfd-spacing-xxs);
}

.summary {
  display: flex;
  gap: 2px;
  align-items: flex-start;
  text-align: left;
  cursor: pointer;

  &:hover {
    color: var(--carrot-3);
  }
}
