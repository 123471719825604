.bannerContentContainer {
  display: flex;
  justify-content: space-between;
  align-items: center;
}

.bannerText {
  text-align: center;
  flex-grow: 1;
  padding: 0 10px;
}

.homeBannerText {
  text-align: center;
  flex-grow: 1;
  padding: 0;
}

.accountBanner {
  padding: 8px var(--margin-xs) 8px var(--margin-sm);
  @media (--viewport-lg) {
    padding: 4px var(--margin-sm);
  }
}

.homeBanner {
  padding: 0px;
  /* Necessary to make banner less prominent on homepage */
  min-height: 25px !important;

  /* Non-standard breaks used to fine-tailor homepage experience specific to this banner */
  @media (min-width: 365px) {
    padding-left: 6px;
  }

  @media (min-width: 390px) {
    padding-left: 37px;
  }
}

.closeButton {
  display: flex;
  justify-content: center;
  align-items: center;
  position: relative;
}
