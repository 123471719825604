@use '@farmersdog/corgi-x/src/styles/constants-legacy.scss' as *;

.question {
  display: flex;
  flex-direction: column;
}

.question + .question {
  margin-top: $margin-sm;
}

.md.question + .md.question {
  margin-top: $margin-md;
}

.md.question {
  flex-direction: row;
  align-items: center;
}

.label {
  margin-bottom: $margin-xs;
}

.md .label {
  margin-top: 0;
  margin-right: auto;
  margin-bottom: auto;
}

.xs .option + .option {
  margin-top: $margin-sm;
}

.sm .option + .option {
  margin-top: $margin-xs;
}

.md .option + .option {
  margin-top: $margin-xs;
}

.options-container {
  padding: 0;
}

.options {
  display: flex;
  flex-direction: column;
}

.sm .options,
.md .options {
  display: block;
}
