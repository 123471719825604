.heroImageContainer {
  position: relative;
  min-height: 350px;
}

.heroImageContainer picture {
  width: 100%;
}

.heroImage {
  height: 100%;
  width: 100%;
  object-fit: cover;
}

.heroImageCta {
  bottom: var(--tfd-spacing-xl);
  left: 50%;
  transform: translateX(-50%);
  position: absolute;
}
