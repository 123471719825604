.iconItemsContainer {
  gap: 18px;
}

.icon {
  width: 24px;
  height: 24px;
  flex-shrink: 0;
}

.iconText {
  font-weight: 500; /* TODO: add medium weight font */
  letter-spacing: 0.5px;
  line-height: 12px;
  margin-left: 8px;
}
