.submitButton {
  width: auto;
}

.submitButtonV2 {
  margin-bottom: var(--tfd-spacing-sm);
}

@media (--viewport-lg) {
  .submitButton {
    width: 100%;
  }
}
