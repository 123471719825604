@use '@farmersdog/corgi-x/src/styles/constants-legacy.scss' as *;

.form-grid {
  display: grid;
  row-gap: $margin-sm;
}

.button {
  justify-self: end;
}

.instructions {
  margin-bottom: $margin-sm;
}

.splitSelect {
  margin-bottom: $margin-sm;
}

.lockedSplits {
  margin-bottom: $margin-sm;
}
