@use '@farmersdog/corgi-x/src/styles/constants-legacy.scss' as *;

.container {
  margin: $margin-lg 0;
  display: grid;
  grid-gap: $margin-sm;
}

.xs.container {
  grid-template-columns: 1fr 1fr;
}

.lg.container {
  grid-template-columns: 1fr 1fr 1fr;
}

.imageContainer {
  position: relative;
}

.imageContainer:before {
  display: block;
  content: '';
  width: 100%;
  padding-top: calc(7 / 13) * 100%;
}

.image {
  position: absolute;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  width: 100%;
  height: 100%;
  object-fit: cover;
}
