.container {
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;

  > svg {
    margin: 0 1px;
  }
}
