.container {
  margin-bottom: var(--tfd-spacing-xxl);
  display: flex;
  align-items: center;
}

.title {
  margin-bottom: var(--tfd-spacing-xs);
}

.description {
  display: block;
}

@media (--viewport-xs) {
  .reverseRow {
    flex-direction: column;
  }

  .row {
    flex-direction: column;
  }

  .benefitText {
    display: 'flex';
    flex-wrap: 'wrap';
    align-items: center;
    align-content: center;
    justify-content: left;
    margin-left: var(--tfd-spacing-md);
    margin-right: var(--tfd-spacing-md);
    text-align: left;
  }

  .benefitImage {
    width: 100%;
    max-width: 530px;
    margin-bottom: var(--tfd-spacing-md);
  }
}

@media (--viewport-md) {
  .reverseRow {
    flex-direction: row-reverse;
  }

  .row {
    flex-direction: row;
  }

  .benefitText {
    display: 'flex';
    flex-wrap: 'wrap';
    align-items: center;
    align-content: center;
    justify-content: left;
    margin-left: var(--tfd-spacing-4xl);
    margin-right: 0px;
    width: 550px;
  }

  .benefitImage {
    width: 100%;
    max-width: 530px;
    margin-bottom: 0px;
  }

  .benefitTextLeft {
    margin-left: 0px;
    margin-right: var(--tfd-spacing-4xl);
    width: 510px;
  }
}
