@use '@farmersdog/corgi-x/src/styles/constants-legacy.scss' as *;

.container {
  padding: 0 4px 4px;
}

.start {
  text-align: start;
}

.center {
  text-align: center;
}

.end {
  text-align: end;
}

.xs .copy {
  margin: $margin-sm 0;
}

.lg .copy {
  margin: $margin-md 0;
}

.content {
  margin-top: $margin-sm;
}
