.wrapper {
  width: 100%;
  overflow: hidden;
  position: relative;
  background-color: #f0f3f0;
}

.marquee {
  padding: var(--margin-xs) 0;
  display: inline-flex;
  white-space: nowrap;
  animation: marquee var(--marquee-duration, 10s) linear infinite;
}

.text {
  padding: 0 1rem;
  display: inline-block;
}

@keyframes marquee {
  0% {
    transform: translateX(0);
  }
  100% {
    transform: translateX(calc(-100% / var(--copies)));
  }
}
