@use '@farmersdog/corgi-x/src/styles/constants-legacy.scss' as *;

.container {
  background: $chickpea-1;
  padding: 72px 30px 96px;

  h2 {
    max-width: 375px;
    margin: 12px auto 0;
  }

  > p {
    max-width: 375px;
    margin: 6px auto 0;
  }

  .iconWrapper {
    max-width: 375px;
    margin: 0 auto;
  }

  .foodCompare {
    text-align: center;

    img {
      width: 100%;
      max-width: 375px;
      margin: 24px auto 0;
    }

    figcaption {
      margin-top: 12px;
    }
  }

  &.lg {
    display: flex;
    justify-content: center;
    padding: 96px 30px;

    .column {
      max-width: 375px;
      flex: 1 0 0;
    }

    .column + .column {
      margin-left: 30px;
    }

    .endColumn {
      text-align: center;
      margin: 0 30px;
    }

    .foodCompare {
      margin: 0;
    }

    .brettKitchen {
      margin-top: 319px;
    }

    .jonathanVan {
      margin-top: 301px;
    }

    .line {
      height: 96px;
      margin: 48px auto;

      &.lineTop {
        margin: -144px auto 48px;
      }

      &.lineBottom {
        margin: 96px auto -144px;
      }
    }

    .funFact {
      &.funFactDooWop {
        margin-top: 174px;
      }

      &.funFactName {
        margin-top: 239px;
      }
    }
  }
}

.accessibilityExperimentContainer {
  padding: 0px 0px 0px;

  h2 {
    max-width: 375px;
    margin: 12px auto 0;
  }

  > p {
    max-width: 375px;
    margin: 6px auto 0;
  }

  .iconWrapper {
    max-width: 375px;
    margin: 0 auto;
  }

  .foodCompare {
    text-align: center;

    img {
      width: 100%;
      max-width: 375px;
      margin: 24px auto 0;
    }

    figcaption {
      margin-top: 12px;
    }
  }

  &.lg {
    display: flex;
    justify-content: center;
    padding: 90px 0px 30px;

    .column {
      max-width: 375px;
      flex: 1 0 0;
    }

    .column + .column {
      margin-left: 30px;
    }

    .endColumn {
      text-align: center;
      margin: 0 30px;
    }

    .foodCompare {
      margin: 0;
    }

    .brettKitchen {
      margin-top: 319px;
    }

    .jonathanVan {
      margin-top: 301px;
    }

    .line {
      height: 96px;
      margin: 48px auto;

      &.lineTop {
        margin: -144px auto 48px;
      }

      &.lineBottom {
        margin: 96px auto -144px;
      }
    }

    .funFact {
      &.funFactDooWop {
        margin-top: 174px;
      }

      &.funFactName {
        margin-top: $margin-xl;
      }
    }
  }
}

.funFact {
  display: block;
  max-width: 375px;
  padding: 32px 24px 35px;
  margin: 36px auto 0;
  text-align: left;

  > p {
    margin: 12px auto 0;
  }

  > a {
    display: block;
    margin-top: 25px;
  }
}

.brettKitchen,
.jonathanVan {
  text-align: center;
  margin: 36px 0 auto;

  img {
    width: 100%;
    max-width: 375px;
  }

  figcaption {
    margin-top: 12px;
  }
}

.line {
  width: 2px;
  height: 72px;
  margin: 36px auto;
  background: $carrot-2;

  &.lineTop {
    margin: -114px auto 42px;
  }

  &.lineBottom {
    margin: 36px auto -114px;
  }
}

.sectionSpace {
  width: 2px;
  height: 72px;
  margin: 36px auto;
}

.spotifyLink {
  display: block;
  margin-top: 25px;
}

.bowlAnimated {
  display: block;
  width: 100%;
  max-width: 375px;
  margin: 36px auto 0;
}

.overlap {
  position: relative;
  z-index: 1;
}
