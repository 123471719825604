/**
  * This is a generated file. Do not modify directly.
  * Instead use generateKeyframes.js
  */
@keyframes spring-down-out {
  0% {
    transform: translate(0px, 0px);
  }
  2% {
    transform: translate(0px, 2.07px);
  }
  4% {
    transform: translate(0px, 4.73px);
  }
  6% {
    transform: translate(0px, 7.41px);
  }
  8% {
    transform: translate(0px, 9.73px);
  }
  10% {
    transform: translate(0px, 11.52px);
  }
  12% {
    transform: translate(0px, 12.72px);
  }
  14% {
    transform: translate(0px, 13.38px);
  }
  16% {
    transform: translate(0px, 13.62px);
  }
  18% {
    transform: translate(0px, 13.55px);
  }
  20% {
    transform: translate(0px, 13.29px);
  }
  22% {
    transform: translate(0px, 12.96px);
  }
  24% {
    transform: translate(0px, 12.61px);
  }
  26% {
    transform: translate(0px, 12.31px);
  }
  28% {
    transform: translate(0px, 12.08px);
  }
  30% {
    transform: translate(0px, 11.92px);
  }
  32% {
    transform: translate(0px, 11.83px);
  }
  34% {
    transform: translate(0px, 11.79px);
  }
  38% {
    transform: translate(0px, 11.83px);
  }
  40% {
    transform: translate(0px, 11.87px);
  }
  42% {
    transform: translate(0px, 11.92px);
  }
  44% {
    transform: translate(0px, 11.96px);
  }
  46% {
    transform: translate(0px, 11.99px);
  }
  100% {
    transform: translate(0px, 12px);
  }
}
