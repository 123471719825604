@use '@farmersdog/corgi-x/src/styles/constants-legacy.scss' as *;

.options-container {
  display: flex;
  flex-direction: column;
}

.option + .option {
  margin-top: $margin-sm;
}

.other-protein-field {
  margin-top: $margin-sm;
}
