@use '@farmersdog/corgi-x/src/styles/constants-legacy.scss' as *;

.container {
  text-align: left;
}

.question + .question {
  margin-top: $margin-xl;
}

.question-text {
  margin-bottom: $margin-sm;
}

.row {
  display: flex;
  flex-wrap: wrap;
}

.col {
  flex-basis: 100%;
}

.col + .col {
  margin-top: $margin-md;
}

.md .col {
  flex-basis: 50%;
  margin-top: 0;
}

.selector-group {
  display: flex;
  flex-direction: column;
}

.selector + .selector {
  margin-top: $margin-sm;
}

.other-protein-field {
  margin: $margin-sm;
  margin-bottom: 0;
}

.row .other-protein-field {
  padding: 0 $margin-sm;
}
