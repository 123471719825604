@use '@farmersdog/corgi-x/src/styles/constants-legacy.scss' as *;

.main {
  padding: $margin-xl 0;
}

.header {
  text-align: center;
}

.center {
  text-align: center;
  padding-left: 16px;
  padding-right: 16px;
}

.pageTitleDescription {
  margin: $margin-xs 0 $margin-md;
  @media (min-width: $lg) {
    margin: $margin-lg 0;
  }
}

.positionsContainer {
  margin-top: $margin-xl;
}

.linkContainer {
  width: 100%;
  display: flex;
  justify-content: center;
}

.fraudWarning {
   margin-bottom: $margin-xl;
}

.subHeader {
  margin-bottom: $margin-lg;
}

.deiAndEmploymentCopy > p {
  margin-top: $margin-sm;
  @media (min-width: $md) {
    margin: $margin-lg 0;
  }
}
