.section {
  margin: 0 16px;
  padding: 0 8px;
  text-align: center;
  width: stretch;
}

.subtitle {
  margin: var(--margin-md) 0;
  font-weight: 500;
}

.tile {
  position: relative;
  height: 100%;

  button {
    position: absolute;
    bottom: 14px;
    right: 12px;
    padding: 0;
  }

  picture {
    display: block;
    width: 100%;
  }
}

.openIcon {
  width: 30px;
  height: 30px;
}

.tileTitle {
  position: absolute;
  top: 20px;
  left: 12px;
  font-size: 18px;
  width: 100%;
}

.tileImage {
  width: 100%;
  object-fit: cover;
}
