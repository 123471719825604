@use '@farmersdog/corgi-x/src/styles/constants-legacy.scss' as *;

.main {
  text-align: center;
}

.logo {
  padding: 12px 48px 0px;
  text-align: center;
}
.widescreen .logo {
  text-align: left;
  padding: 12px 78px 0px;
}

.header {
  margin-top: $margin-lg;
}

.subHeader {
  margin-top: $margin-xs;
  margin-bottom: 0;
}

.content {
  padding: 0 $margin-md;
  padding-bottom: $margin-xxl;
  margin: $margin-lg auto 0;
}

@media (min-width: $lg) {
  .content {
    min-width: 350px;
  }
}

.widescreen .content {
  margin-top: $margin-lg;
}

.nav {
  position: sticky;
  top: 0;
  z-index: var(--tfd-appnav-z-index);
}
