.container {
  position: relative;
  height: 600px;
  text-align: center;
}

.gradient {
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
  position: absolute;
  background-image: linear-gradient(
    to bottom,
    rgba(#39383c, 0.6),
    rgba(#39383c, 0.12)
  );
}

.header {
  position: absolute;
  left: 8%;
  bottom: 15%;
  text-align: left;
}

.background {
  height: 100%;

  // IE doesn't support
  @supports (object-fit: cover) and (object-position: 75% 50%) {
    width: 100%;
    object-fit: cover;
    object-position: 75% 50%;
  }
}

@media (--viewport-sm) {
  .header {
    left: 9%;
    bottom: 28.5%;
    width: 315px;
  }
}

@media (--viewport-lg) {
  .header {
    bottom: 15%;
    left: 8%;
    width: 480px;
  }
}
