.grid {
  gap: 60px;
  padding-top: 60px;
}

@media (--viewport-lg) {
  .grid {
    gap: var(--margin-xxl);
    padding-top: var(--margin-xxl);
  }
}
