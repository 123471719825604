.header {
  font-size: 28px;
  margin-bottom: var(--margin-xs);
}

.subSections {
  margin: var(--margin-md) 0;
}

.subHeader {
  margin: var(--margin-xxs) 0;
}

.ingredients,
.ingredientsSub {
  margin: var(--margin-xs) 0;
}

.analysis {
  max-width: 210px;
  margin: var(--margin-sm) 0;
}

.analysisItem {
  display: inline-block;
  width: 60%;
}

.analysisPercentage {
  display: inline-block;
  width: 40%;
}

.calorieContent {
  margin: var(--margin-xs) 0;
}

.border {
  border: 1px solid var(--kale-2);
}
