@use '@farmersdog/corgi-x/src/styles/constants-legacy.scss' as *;
@use 'styles/constants.scss' as *;

.lg {
  max-width: 1365px;
  margin-left: auto;
  margin-right: auto;
}

.xs .content {
  margin-top: $margin-md;
}

.lg .content {
  margin-top: $margin-xxl;
}

.media-container {
  display: flex;
  flex-wrap: nowrap;
}

.xs .media-container {
  flex-direction: column;
  align-items: flex-start;
}

.lg .media-container.start {
  flex-direction: row;
  align-items: center;
}

.lg .media-container.end {
  flex-direction: row-reverse;
  align-items: center;
}

.media img {
  width: 100%;
  display: block;
}

.xs .media {
  width: 100%;
}

.lg .media {
  width: 840px;
}

.lg .start .media {
  margin-left: 0;
  margin-right: $margin-3xl;
}

.lg .end .media {
  margin-right: 0;
  margin-left: $margin-3xl;
}

.title {
  margin: 0;
}

.xs .title {
  margin-top: $margin-lg;
}

.cta {
  margin-top: $margin-lg;
  text-align: center;
}

.accessibilityTheme.cta {
  background-color: $kale-2 !important;
  border-radius: 30px;
}

.lg .accessibilityTheme.cta {
  width: $cta-desktop-width;
}
