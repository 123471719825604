.container {
  margin-top: var(--tfd-spacing-xxl);
}

@media (--viewport-xs) {
  .title {
    margin-bottom: var(--tfd-spacing-md);
    text-align: left;
  }

  .subtitle {
    text-align: left;
    margin-bottom: 0;
  }

  .subtitle .mobile-break {
    display: block;
  }
}

@media (--viewport-md) {
  .title {
    margin-bottom: var(--tfd-spacing-sm);
    text-align: center;
  }

  .subtitle {
    text-align: center;
    margin-top: 0;
  }

  .subtitle .mobile-break {
    display: inline;
  }
}
