.visible {
  margin: 0;
  overflow: hidden;
  margin-left: auto;
  display: block;
  position: relative;
}

.xs.visible {
  width: 297px;
  height: 180px;
}

.lg.visible {
  width: 495px;
  height: 272px;
}

.xs .aspect {
  width: 297px;
  height: 413px;
}

.lg .aspect {
  height: 717px;
  width: 495px;
}

.image {
  position: absolute;
  display: block;
  width: 100%;
}
