.container {
  overflow-wrap: break-word;
}

.table-container {
  overflow-x: scroll;
  width: 100%;
}

.lg .table-container {
  overflow-x: auto;
}

.table {
  table-layout: fixed;
  width: 100%;
}

.xs .column {
  width: calc(var(--tfd-constraint-width-sm) * 0.75);
}

.md .column {
  width: calc(var(--tfd-constraint-width-md) * 0.5);
}

.lg .column {
  width: 25%;
}

.form {
  margin-top: var(--tfd-margin-lg);
}

.do-not-track,
.california-individual-privacy-rights,
.shine-the-light,
.global-privacy-control {
  font-weight: lighter !important;
  text-decoration: underline;
  font-style: italic;
}
