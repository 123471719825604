.container {
  overflow: hidden;
}

.slider {
  padding: var(--margin-3xl) var(--margin-md) var(--margin-xxl) var(--margin-md);
  background-color: var(--chickpea-0);
}

.slider * {
  /* For aligning the slider buttons to the left */
  justify-content: flex-start !important;
}

.split-top-overlay::before,
.split-bottom-overlay::before,
.split-middle-overlay::before {
  position: absolute;
  background-color: var(--chickpea-2);
  padding: var(--margin-md);
  content: '';
  display: block;
  z-index: -1;
}

.split-top-overlay::before {
  top: -21px;
  left: -22px;
  width: 330px;
  height: 218px;
}

.split-bottom-overlay::before {
  right: -55px;
  bottom: -21px;
  width: 332px;
  height: 245px;
}

.desktopBreakline {
  display: none;
}

@media (--viewport-lg) {
  .container {
    overflow: visible;
  }

  .slider {
    display: none;
  }

  .desktopBreakline {
    display: block;
  }

  .split-top-overlay::before {
    top: -38px;
    left: -115px;
    width: 504px;
    height: 367px;
  }

  .split-bottom-overlay::before {
    left: -83px;
    bottom: -35px;
    width: 485px;
    height: 445px;
  }

  .split-middle-overlay::before {
    right: -88px;
    bottom: 85px;
    width: 496px;
    height: 397px;
  }
}
