@media (--viewport-xs) {
  .section {
    grid-column: 1 / span 4;
    margin-bottom: var(--margin-xxl);
  }
}

@media (--viewport-md) {
  .section {
    grid-column: 1 / span 12;
    margin-bottom: var(--margin-3xl);
  }
}
