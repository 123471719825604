.container {
  display: flex;
  flex-direction: column;
  gap: var(--tfd-spacing-md);
  min-height: 264px;
  /* Override fade in/out duration for this component */
  --animation-standard-duration: 1000ms;
}

.slide {
  width: 28% !important;
  margin: 0 2% !important;
}

.slide:first-child {
  margin-left: 36% !important;
}

.slide:last-child {
  margin-right: 36% !important;
}

.slideImage {
  width: 100%;
  height: 100%;
  object-fit: contain;
}
