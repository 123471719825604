@use 'styles/constants.scss' as *;

.kaleBackground {
  width: 100%;
  background-color: var(--kale-0);
}

.oysterBackground {
  width: 100%;
  background-color: var(--oyster-0);
}

.backgroundPadding {
  padding: var(--margin-xxl) 0;
}

.primaryCta {
  background-color: var(--kale-0) !important;
  color: var(--kale-3) !important;

  &:hover {
    background-color: var(--white) !important;
  }
  &[aria-disabled='true'] {
    background-color: var(--charcoal-2) !important;
    color: var(--white) !important;
  }
}

.mobileCta,
.desktopCta,
.responsiveCta {
  background-color: var(--kale-2) !important;
  /* emulates the pill button shape without having to change the variant */
  border-radius: 30px !important;
  &:hover {
    background-color: var(--kale-3) !important;
  }
}

.navigationCta {
  background-color: var(--kale-2) !important;
  &:hover {
    background-color: var(--kale-3) !important;
  }
}

.mobileCta {
  width: 100%;
}

.desktopCta {
  min-width: $cta-desktop-width !important;
}

.card {
  border-radius: 4px !important;
}

@media (--viewport-xs) {
  .responsiveCta {
    width: 100%;
  }
}

@media (--viewport-lg) {
  .responsiveCta {
    width: $cta-desktop-width !important;
  }
}
