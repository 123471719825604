@use '@farmersdog/corgi-x/src/styles/constants-legacy.scss' as *;

.section {
  width: 100%;
  padding: 0;
  margin-top: $margin-lg;
}

.infoContainer {
  margin-top: $margin-sm;
}

.buttonContainer {
  margin: $margin-xl 0 $margin-xxl 0;
}

@media (min-width: $lg) {
  .buttonContainer {
    margin: $margin-xxl 0 $margin-4xl 0;
  }
}
